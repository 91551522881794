import { diff, now } from './utils/dates';
import { DOMAIN_CACHETIME } from './store/reducers';
import Immutable from 'seamless-immutable';
import { IS_PRODUCTION } from './config';

export const CACHE_EXPIRY_TIME = 5;
export const CACHE_EXPIRY_UNIT = 'minutes';
export const CACHE_KEY = 'lse-welcome-2020';
export const CACHE_KEY_COOKIE = 'lse-welcome-2020-cookie';

export const cacheShouldClear = (store = {}) => {
  const diffedDate = diff(now(), store[DOMAIN_CACHETIME], CACHE_EXPIRY_UNIT);
  const diffedDateAbs = Math.abs(diffedDate);
  const cacheExpired = (diffedDateAbs > CACHE_EXPIRY_TIME);
  if (cacheExpired) {
    console.info(`cache expired: ${diffedDateAbs} ${CACHE_EXPIRY_UNIT} old`);
  }
  return cacheExpired;
};

export const load = () => {
  try {
    const cache = IS_PRODUCTION ? localStorage.getItem(CACHE_KEY) : null;
    if (cache === null) return undefined;

    const store = JSON.parse(cache);

    if (cacheShouldClear(store)) {
      localStorage.removeItem(CACHE_KEY);
      return undefined;
    }

    return Immutable.from(store);
  } catch (err) {
    return undefined;
  }
};

export const save = ({ ...store }) => {
  try {
    store[DOMAIN_CACHETIME] = now();
    const cache = JSON.stringify(store);
    localStorage.setItem(CACHE_KEY, cache);
  } catch (err) {
    // don't do anything, really.
  }
};

export const loadCookie = () => {
  try {
    const cookie = localStorage.getItem(CACHE_KEY_COOKIE);
    if (cookie === null) return false;
    const cookieBool = JSON.parse(cookie);
    return Immutable.from(cookieBool);
  } catch (err) {
    return undefined;
  }
};

export const saveCookie = (cookiesEnabled = false) => {
  try {
    localStorage.setItem(CACHE_KEY_COOKIE, cookiesEnabled);
  } catch (err) {
    // don't do anything, really.
  }
};
