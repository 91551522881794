import styled from 'styled-components';

import { routes } from '../../routes';

import { ButtonSearch } from '../Button';

export const Input = styled.input`
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.dantooine};

  background: transparent;
  font-size: 1.6rem;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.tatooine};
  padding: 1.1rem 0 1rem;

  transition:
    0.1s ease border-color,
    0.1s ease color;

  ::-webkit-input-placeholder {
    color: #555555;
  }
  ::-moz-placeholder {
    color: #555555;
  }
  :-ms-input-placeholder {
    color: #555555;
  }
  :-moz-placeholder {
    color: #555555;
  }

  &:focus {
    outline: none;
  }
  &:focus,
  &:hover {
    border-bottom-color: ${({ theme }) => theme.colors.tatooine};
  }
`;

export const SearchForm = styled.form.attrs({
  action: routes.SEARCH,
  method: 'GET',
})`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0;
  align-items: center;

  ${Input} {
    width: 100%;
  }

  ${ButtonSearch} {
    height: 4rem;
    margin-left: 0.7rem;
  }
`;
