import React from 'react';

import { makeSelectors } from './selectors';
import { storeReducer, initialState, initialStateFromCache, withCache } from './reducers';
import { makeActionsWithDispatch } from './actions';

export const StoreContext = React.createContext();


/**
 * Hook for store access
 *
 */
export const useStore = () => {
  const [state, dispatch] = React.useReducer(
    withCache(storeReducer),
    initialState,
    initialStateFromCache,
  );
  const actions = makeActionsWithDispatch(dispatch);

  return {
    actions,
    ...makeSelectors(state),
  }
};
