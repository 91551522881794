import React from 'react';
import styled from 'styled-components';

import { Icon } from '../Icon';

export const Button = styled(({ className, ...props}) =>
  <button className={className} {...props} />
)`
  background: none;
  padding: 1.9rem 2.4rem;
  font-size: 1.6rem;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 500;
  line-height: 1;
  color: ${({ theme }) => theme.colors.tatooine};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.dantooine};
  white-space: nowrap;
  cursor: pointer;
  transition:
    0.1s ease color,
    0.1s ease background-color;
`;

export const ButtonCategory = styled(Button)`
  text-transform: uppercase;
  letter-spacing: .2rem;
  font-size: 1.2rem;
  padding: 1.7rem 1.6rem;
`;

export const ButtonMenu = styled(Button)`
  border: none;
  padding: 0;
  font-weight: 400;
  font-size: 1.4rem;
  ${Icon} {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
  }
`;

export const ButtonSearch = styled(Button)`
  background: ${({ theme }) => theme.colors.hoth};
  border: 1px solid ${({ theme }) => theme.colors.hoth};
  color: ${({ theme }) => theme.colors.naboo};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 1.6rem;
  line-height: 1;
  padding: 1rem;

  &:focus,
  &:hover {
    background-color: ${({ theme }) => theme.colors.naboo};
    color: ${({ theme }) => theme.colors.hoth};
  }
`;
