import React from 'react';

export const useScrollToTop = (skipEffect = [window.location.pathname]) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    return () => null;
  }, skipEffect);
}

export const ScrollToTop = () => {
  useScrollToTop();
  return null;
};
