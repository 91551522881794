import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { SkeletonImage } from '../Skeleton';
import brokenSvg from './broken.svg';

export const ImgContainer = styled.div`
  margin: 0;
`;

export const ImgInner = styled.div``;

export const ImgComponent = styled.img`
  max-width: 100%;
  display: block;
  ${({ async }) => async && css`
    transition: 0.3s .1s ease opacity;
    opacity: 0;
  `}
  ${({ async, loaded }) => (async && loaded) && css`
    opacity: 1;
  `}
  ${({ error }) => (error) && css`
    opacity: 1;
    background: transparent url(${brokenSvg}) no-repeat center center;
    background-size: 20%;
  `}
`;

export const Img = styled(({ className, src, async, alt, ...props }) => {
  let loaded = (async) ? false : true;
  let error = false;

  if (async) {
    const { hasError, hasLoaded } = useImageAsync(src);
    loaded = hasLoaded;
    error = hasError;
  }

  return (
    <ImgContainer className={className}>
      <ImgInner>
        <ImgComponent
          src={src}
          async={async}
          loaded={loaded}
          error={error}
          alt={alt || src}
          {...props}
        />
        {!error && <SkeletonImage />}
      </ImgInner>
    </ImgContainer>
  );
})`
  ${SkeletonImage} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  ${ImgInner} {
    position: relative;
  }
  ${ImgComponent} {
    position:relative;
    z-index: 1;
  }
`;

Img.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  aysnc: PropTypes.bool,
};

Img.defaultProps = {
  src: '',
  aysnc: false,
};

const useImageAsync = src => {
  const [hasLoaded, setHasLoaded] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const [hasStartedInitialFetch, setHasStartedInitialFetch] = React.useState(false);

  React.useEffect(() => {
    setHasStartedInitialFetch(true);
    setHasLoaded(false);
    setHasError(false);

    const image = new Image();
    image.src = src;

    const handleError = () => setHasError(true);

    const handleLoad = () => {
      setHasLoaded(true);
      setHasError(false);
    };

    image.addEventListener('error', handleError);
    image.addEventListener('load', handleLoad);

    return () => {
      image.removeEventListener('error', handleError);
      image.removeEventListener('load', handleLoad);
    };
  }, [src]);

  return { hasLoaded, hasError, hasStartedInitialFetch };
};
