import React from 'react';
import ReactSkeleton from 'react-loading-skeleton';
import styled from 'styled-components';

export const Skeleton = styled(ReactSkeleton)`
  margin-bottom: 1rem;
  height: 2rem;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const SkeletonImage = styled(props => <Skeleton count={1} {...props} />)`
  &:first-of-type {
    border-radius: 0;
  }
`;

export const SkeletonHeroImage = styled(props => <Skeleton count={1} {...props} />)`
  &:first-of-type {
    height: 61rem;
  }
`;

export const SkeletonArticleContent = styled((props) => <Skeleton count={12} {...props} />)`
  &:nth-of-type(0n+1) {
    height: 10rem;
    margin-bottom: 5rem;
  }
  &:nth-of-type(0n+4) {
    margin-bottom: 5rem;
  }
  &:nth-of-type(0n+5) {
    height: 30rem;
    margin-bottom: 5rem;
  }
`;

export const SkeletonArticleSidebar = styled((props) => <Skeleton count={12} {...props} />)`
  &:nth-of-type(4n + 1) {
    height: 4rem;
    margin-bottom: 3rem;
  }

  &:nth-of-type(4n + 5) {
    margin-top: 6rem;
  }
`;

export const SkeletonSplash = styled((props) => <Skeleton count={1} {...props} />)`
  &:nth-of-type(0n + 1) {
    height: 30rem;
  }
`;

export const SkeletonArticlesList = styled((props) => <Skeleton count={8} {...props} />)`
  &:nth-of-type(1n + 0) {
    height: 20rem;
  }
`;

export const SkeletonCardCollection = styled((props) => (
  <div {...props}>
    <Skeleton count={3} />
  </div>
))`
  > span {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;
    height: 30rem;
  }

  ${Skeleton} {
    &:nth-of-type(0n + 1) {
      height: 100%;
    }
    &:nth-of-type(0n + 2) {
      height: 70%;
    }
    &:nth-of-type(0n + 3) {
      height: 80%;
    }
  }
`;

export const SkeletonCardCollections = styled(({ count = 4 , ...props }) => (
  <div {...props}>
    {Array(count).fill(null).map((_item, i) => <SkeletonCardCollection key={`skel-${i}`} />)}
  </div>
))`

`;
