import React from 'react';
import styled from 'styled-components';

import { getImageDataFromMediaObject } from '../../utils/assets';

import { Img } from '../Img';

export const FigCaption = styled.figcaption`
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.fonts.primary};
  line-height: 1.5;
  color: inherit;
`;

export const Figure = styled.figure`
  margin: 0;
  ${FigCaption} {
    margin-top: 1.6rem;
  }
`;

// Check usage
export const FigureAsset = styled(({ media, ...props }) => {
  const img = getImageDataFromMediaObject(media);
  const { width, height, fileSize: size } = img.properties;
  return (
    <Figure {...props}>
      <Img async src={img.src} alt={img.alt} data-size={size} data-dims={`${width}x${height}`} />
      {img.caption && <FigCaption>{img.caption}</FigCaption>}
    </Figure>
  );
})`

`;
