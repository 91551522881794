import React from 'react';
import styled from 'styled-components';

import { useApi } from '../../api';
import { makeRoute } from '../../routes';
import { getCardProps } from '../../utils/articles';
import { wordLimit } from '../../utils/strings';

import { useScrollToTop } from '../../components/ScrollToTop';
import { Page } from '../../components/Page';
import { CardCollection, CardCollectionInner, CardCollectionColumn } from '../../components/CardCollection';
import { CardPrimary, CardSecondary, CardTertiary, CardLink } from '../../components/Card';
import { WelcomeSplash, SplashInner, SplashMessage, SplashMessageTitle } from '../../components/WelcomeSplash';
import { Link } from '../../components/Link';
import { Button } from '../../components/Button';
import { Ol, Li } from '../../components/List';
import { VideoYouTube } from '../../components/Video';
import { Skeleton, SkeletonSplash, SkeletonCardCollections,
  SkeletonCardCollection } from '../../components/Skeleton';

const TopicLinks = styled(Ol)`
  padding: 2rem 0 4rem 0;
  padding-left: 0;

  display: flex;
  justify-content: space-between;
  list-style-type: none;
  gap: 2rem;
  flex-direction: column;

  @media (min-width: 580px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const TopicLinkItem = styled(Li)`
  margin: 0;
  flex: 1 1 100%;

  @media (min-width: 580px) {
    flex: 0 1 45%;
  }
  @media (min-width: 850px) {
    flex: 0 1 31%;
  }
  @media (min-width: 1200px) {
    flex: 0 1 auto;
  }
  ${Link} {
    text-decoration: none;

  }
`;

export const BroadCastVideo = styled(VideoYouTube)``;

export const BroadCastSplash = styled(WelcomeSplash)``;

export const renderBroadcast = broadcast => {
  if (!broadcast.showOrConceal) return null;
  const key = broadcast.sys.id;
  return !broadcast.displayTitleCardOrStream
    ? <BroadCastVideo key={key} src={broadcast.youTubeLink.youTubeLink} />
    : <BroadCastSplash
        key={key}
        title={broadcast.videoMainTitle}
        description={broadcast.videoSubtitle}
        media={broadcast.titleCardImage}
      />;
}

export const renderBroadCasts = broadcasts => broadcasts.map(renderBroadcast);

const Home = ({ className }) => {
  useScrollToTop();
  const { topicCollections, homepageSplash, topicCollectionsLoading,
    homepageSplashLoading, broadcastsLoading, broadcasts } = useApi();

  return (
    <Page className={className}>
      <TopicLinks>
        {topicCollections.map(({ topic }) => (
          <TopicLinkItem key={topic.sys.id}>
            <Button as={Link} href={makeRoute(topic.sys.slug)}>
              {topic.entryTitle}
            </Button>
          </TopicLinkItem>
        ))}
      </TopicLinks>

      {homepageSplashLoading
        ? <SkeletonSplash />
        : <WelcomeSplash
            title={homepageSplash.introductoryText}
            description={homepageSplash.subtitle}
            media={homepageSplash.mainSplashImage}
          />}

      {broadcastsLoading
        ? <SkeletonSplash />
        : renderBroadCasts(broadcasts)}

      {topicCollectionsLoading
        ? <SkeletonCardCollections />
        : topicCollections.map(({ topic, articles }) => {
          const [primaryArticle, ...restArticles] = articles.filter(({ suppressArticleOnHomepage }) => !suppressArticleOnHomepage);
          const secondaryArticles = restArticles.slice(0, 2);
          const tertiaryArticles = restArticles.slice(2, 5);

          const primaryCardProps = getCardProps(primaryArticle);
          primaryCardProps.subtitle = wordLimit(primaryCardProps.subtitle, 20);

          return (
            <CardCollection
              topic={topic}
              key={`topic-${topic.sys.id}`}
              id={topic.sys.id}
            >
              <CardCollectionColumn>
                <CardLink href={makeRoute(topic.sys.slug, primaryArticle.sys.slug)}>
                  <CardPrimary {...primaryCardProps } description={null}/>
                </CardLink>
              </CardCollectionColumn>

              <CardCollectionColumn>
                <div>
                  {secondaryArticles.map(article => {
                    const cardProps = getCardProps(article);
                    cardProps.subtitle = wordLimit(cardProps.subtitle, 10);
                    return (
                      <CardLink key={article.sys.id} href={makeRoute(topic.sys.slug, article.sys.slug)}>
                        <CardSecondary {...cardProps} description={null} />
                      </CardLink>
                    )
                  })}
                </div>

                <div>
                  {tertiaryArticles.map(article => {
                    const cardProps = getCardProps(article);
                    cardProps.subtitle = wordLimit(cardProps.subtitle, 10);
                    return (
                      <CardLink key={article.sys.id} href={makeRoute(topic.sys.slug, article.sys.slug)}>
                        <CardTertiary {...cardProps } description={null} imgSrc={null} />
                      </CardLink>
                    )
                  })}
                </div>

              </CardCollectionColumn>
            </CardCollection>
          );
        })}
    </Page>
  );
};

export default styled(Home)`
  ${WelcomeSplash},
  ${SkeletonSplash} {
    margin-bottom: 2rem;
    min-height: 50vh;
  }

  ${CardCollection},
  ${SkeletonCardCollection},
  ${BroadCastVideo},
  ${BroadCastSplash} {
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${CardCollectionInner} {
    grid-template-columns: [colOne] 4fr [colTwo] 6fr;

    &:nth-child(0n+1) {
      grid-column: colOne;
    }
    &:nth-child(0n+2) {
      grid-column: colTwo;
    }
  }

  ${CardCollectionColumn} {
    &:nth-child(0n+2) {
      display: grid;
      grid-template-columns: 3.5fr 2.5fr;
      grid-gap: 2rem;
    }

    > div {
      grid-auto-rows: max-content;
      display: grid;
      grid-gap: 2rem;
    }
  }

  ${CardPrimary} {
    max-width: none;
  }

  ${({ theme }) => theme.media.alpha`
    ${CardCollectionInner} {
      grid-template-columns: repeat(2, 1fr);
    }

    ${CardCollectionColumn}:nth-child(0n+2) {
      grid-template-columns: 1fr;
    }
  `}

  ${({ theme }) => theme.media.bravo`
    ${SplashMessage} {
      max-width: none;
    }
    ${SplashMessageTitle} {
      width: 100%;
    }

    ${CardCollectionInner} {
      grid-template-columns: 1fr;
    }

    ${SkeletonCardCollection} {
      > span {
        grid-template-columns: 1fr 1fr;
      }

      ${Skeleton}:nth-of-type(0n + 3) {
        display: none;
      }
    }
  `}

  ${({ theme }) => theme.media.charlie`

    ${SkeletonCardCollection} {
      > span {
        grid-template-columns: 1fr;
      }
    }

    ${SplashInner} {
      padding-top: 15rem;
    }
  `}
`;
