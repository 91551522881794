import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ISvg from 'react-inlinesvg';

import quoteStartSrc from './quote-start.svg';
import quoteEndSrc from './quote-end.svg';

export const QuoteStart = styled(props => <ISvg src={quoteStartSrc} {...props} />)`
  color: ${({ theme }) => theme.colors.hoth};
`;

export const QuoteEnd = styled(props => <ISvg src={quoteEndSrc} {...props} />)`
  color: ${({ theme }) => theme.colors.hoth};
`;

export const BlockquoteFooter = styled.footer`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.7;
  color: ${({ theme }) => theme.colors.tatooine};
`;

export const BlockquoteCite = styled.cite`
  font-style: normal;
`;

export const Blockquote = styled(({ children, cite, ...props }) => (
  <blockquote {...props}>
    <QuoteStart />
    {children}
    <QuoteEnd />
    {cite && <BlockquoteFooter><BlockquoteCite>{cite}</BlockquoteCite></BlockquoteFooter>}
  </blockquote>
))`
  font-family: ${({ theme }) => theme.fonts.serif};
  line-height: 2;
  font-size: 2.4rem;
  color: ${({ theme }) => theme.colors.dantooine};
  max-width: 22em;
  margin: 0;
  display: flex;
  flex-direction: column;

  ${QuoteStart} {
    margin-bottom: 0.4rem;
  }

  ${QuoteEnd} {
    align-self: flex-end;
  }

  ${BlockquoteFooter} {
    margin-top: 1rem;
    text-align: right;
  }
`;

Blockquote.propTypes = {
  footer: PropTypes.node,
};

Blockquote.defaultProps = {};
