const pkg = require("./package.json");

const ENV_DEV = process.env.NODE_ENV === "development";
const ENV_PROD = process.env.NODE_ENV === "production";

const baseUrl = ENV_PROD
  ? "https://welcome.lse.ac.uk"
  : "https://welcometest.lse.ac.uk";

const posthtmlLocals = {
  version: pkg.version,
  baseUrl,
  title: `LSE Welcome`,
  description:
    "London School of Economics is unique in its concentration on teaching and research across the full range of social, political and economic sciences.",
  lang: "en-GB",
  keywords:
    "London School of Economics, LSE, social sciences, political sciences, economic sciences, undergraduate courses, postgraduate courses, ground-breaking research",
  googleFonts:
    "https://fonts.googleapis.com/css?family=Roboto:200,200italic,300,300italic,400,400italic,500,500italic,700,700italic&family=Libre+Baskerville:400,400italic,700,700italic",
  googleAnalytics: "GTM-WHR9NPP",
  ogImage: "/images/welcome-2024.jpg",
};

module.exports = {
  posthtmlLocals,
  plugins: {
    "posthtml-expressions": {
      locals: posthtmlLocals,
    },
  },
};
