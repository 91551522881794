import React from 'react';
import styled from 'styled-components';

import { Link } from '../Link';
import { Icon } from '../Icon';
import { Ol, Li } from '../List';

export const BreadcrumbLink = styled(Link)``;

export const BreadcrumbItem = styled(Li)`
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
`;

export const Breadcrumb = styled(({ className, children, ...props }) => {
  const childCount = React.Children.count(children);
  return (
    <Ol className={className} {...props}>
      {React.Children.map(children, (child, i) => (
        <React.Fragment>
          <BreadcrumbItem>
            {(i !== 0 && i < childCount) && <Icon icon="chevronRight" />}
            {child}
          </BreadcrumbItem>
        </React.Fragment>
      ))}
    </Ol>
  );
})`
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  padding: 0;

  ${BreadcrumbLink} {
    padding: 0;
    border: none;
  }

  ${Icon} {
    width: 2.4rem;
    min-width: 2.4rem;
    height: 2.4rem;
    flex-basis: 2.4rem;
  }

  ${({ theme }) => theme.media.charlie`
    ${BreadcrumbItem} {
      align-items: flex-start;
    }
  `}
`;

Breadcrumb.propTypes = {};

Breadcrumb.defaultProps = {};
