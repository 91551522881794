import styled from 'styled-components';

import { Li, Ol, Ul } from '../List';

export const Callout = styled.div`
  background-color: ${({ theme }) => theme.colors.kashyyk};
  padding: 1.6rem;
  border-left: 4px solid ${({ theme }) => theme.colors.hoth};

  ${Ol},
  ${Ul} {
    margin-top: 1.6rem;
  }

  ${Li}:before {
    color: ${({ theme }) => theme.colors.hoth};
  }
`;
