import React from 'react';
import { useLocation } from '@reach/router';
import { parse } from 'query-string';

import { useApi } from '../api';

export const QUERY_KEY = 'q';

export const useSearch = (query, limit = 25) => {
  const { api } = useApi();

  // need state to trigger rerendering in parent component
  const [results, setResults] = React.useState(null);
  const [busy, setBusy] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      if (query && query !== '') {
        setBusy(true);
        const result = await api.searchArticles({
          query,
          pageOptions: {
            pageSize: limit,
          }
        });
        setResults(result.data);
        setBusy(false);
      }
    })();
    return () => {
      setBusy(false);
    }; // TODO: abort?
  }, [query]); // don't re-search unless the query changee

  return {
    results,
    busy,
  };
}

export const useSearchQueryParam = () => {
  const { search: queryParams } = useLocation();
  const { [QUERY_KEY]: query = '' } = parse(queryParams);
  return query;
}
