import { createGlobalStyle, css } from 'styled-components';

import { colors, fonts } from './theme';
import { liveChatStyles } from './components/LiveChat';

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  html {
    font-size: 62.5%;
  }
  body {
    font-size: 1.6rem;
    font-weight: 400;
    font-family: ${fonts.primary};
    line-height: 1.56;
    color: ${colors.tatooine};
    background-color: ${colors.naboo};

    &.noscroll {
      overflow: hidden;
    }
  }
  html, body {
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
  }
  html, body,
  #root,
  #root > div {
    min-height: 100vh;
  }
  h1, h2, h3, h4, h5 {
    margin: 0;
  }

  ${liveChatStyles}
`;

export const containerStyles = css`
  width: 100%;
  margin: auto;
  max-width: ${({ theme }) => theme.dimensions.siteWidth};
  padding: 0 ${({ theme }) => theme.dimensions.sitePadding};

  ${({ theme }) => theme.media.alpha`

  `}

  ${({ theme }) => theme.media.bravo`
    padding-left: calc(${({ theme }) => theme.dimensions.sitePadding} / 2);
    padding-right: calc(${({ theme }) => theme.dimensions.sitePadding} / 2);
  `}
`;
