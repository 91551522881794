import styled from 'styled-components';

import {
  CardPrimary,
  CardPrimaryTitle,
  CardPrimaryFigure,
  CardPrimaryContent,
  CardPrimaryContentBody,
  CardPrimaryDescription,
  CardPrimaryContentFooter,
} from '../CardPrimary';

export const CardTertiary = styled(CardPrimary)`
  max-width: none;
  background: ${({ theme }) => theme.colors.naboo};
  color: ${({ theme }) => theme.colors.coruscant};
  border-bottom: 1px solid ${({ theme }) => theme.colors.alderaan};

  ${CardPrimaryFigure} {
    &:empty {
      display: none;
    }
  }
  ${CardPrimaryContentBody} {
    padding: 0 1em 0 0;
    margin-bottom: 0rem;
  }
  ${CardPrimaryContent} {
    padding: 0 0 1.5rem;
    flex: 1 1 auto;
  }
  ${CardPrimaryDescription} {
    &:empty {
      display: none;
    }
  }
  ${CardPrimaryTitle} {
    font-size: 1.8rem;
  }
  ${CardPrimaryContentFooter} {
    color: ${({ theme }) => theme.colors.dantooine};
  }
`;

const propTypes = {...CardPrimary.propTypes};
delete propTypes.description;
delete propTypes.imgSrc;
delete propTypes.imgAlt;

CardTertiary.propTypes = propTypes;

CardTertiary.displayName = 'CardTertiary';
