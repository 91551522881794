import "regenerator-runtime/runtime";
import React from "react";
import { Client, Query, Op } from "contensis-delivery-api";

import { useStore } from "./store";
import { IS_PRODUCTION } from "./config";

export const contentTypes = {
  WELCOME_ARTICLE: "welcomeArticle",
  WELCOME_TOPIC: "welcomeTopic",
  WELCOME_FOOTER_ITEMS: "welcomeFooterItems",
  WELCOME_HOMEPAGE_SPLASH: "welcomeHomepageSplash",
  WELCOME_LIVE_BROADCAST: "welcomeLiveBroadcast",
  WELCOME_LIST_PAGE_HEADER: "welcomeListPageHeader",
  WELCOME_SITE_CONFIG: "welcomeSiteConfig",
  WELCOME_SOCIAL_MEDIA: "welcomeSocialMedia",
  WELCOME_CHAT_CONTROL: "welcomeChatControl",
};

export const ApiContext = React.createContext();

export const CONTENSIS_API_PROJECT_ID = "website";
export const CONTENSIS_API_ROOT_URL = "https://cms-else.cloud.contensis.com/";

export const VERSION_STATUS = IS_PRODUCTION ? 'published' : 'latest';

export const contensisConfig = {
  rootUrl: CONTENSIS_API_ROOT_URL,
  projectId: CONTENSIS_API_PROJECT_ID,
  accessToken: process.env.CONTENSIS_API_ACCESS_TOKEN,
};

export const apiClient = Client.create(contensisConfig);

/**
 * Wrapper for search to assign the user's query to specific article fields
 * @param {string} params.query
 * @param {object} params.otherArgs
 * @returns {object} The search query
 */
export const searchArticles = ({ query = "", ...otherArgs }) =>
  search({
    query: new Query(
      Op.equalTo("sys.contentTypeId", contentTypes.WELCOME_ARTICLE),
      // required to also avoid duplicates
      Op.equalTo("sys.versionStatus", VERSION_STATUS),
      Op.or(
        Op.freeText("articleTitle", query).weight(10),
        Op.freeText("subtitle", query).weight(5),
        Op.freeText("introductoryText", query).weight(3)
        // TODO: searching in content blobs currently doesn't work
        // Op.freeText('contentBlob[].markup', query).weight(1),
      )
    ),
    ...otherArgs,
  });

/**
 * Search
 * https://developer.zengenti.com/contensis/api/delivery/http/search/search-basics.html
 *
 * @param {object} params
 * @param {string} params.query
 * @param {number} params.linkDepth
 * @param {object} params.pageOptions
 * @param {number} params.pageOptions.pageSize
 * @param {number} params.pageOptions.pageIndex
 */
export const search = async ({
  query,
  linkDepth = 1,
  pageOptions = {
    pageSize: 10,
    pageIndex: 0,
  },
}) => {
  try {
    const result = await apiClient.entries.search(
      {
        ...query,
        ...pageOptions,
      },
      linkDepth
    );
    return makeSuccessfulResponse(result);
  } catch (error) {
    return makeErrorResponse(error);
  }
};

/**
 * Fetch a list of entries
 * https://developer.zengenti.com/contensis/api/delivery/js/model/entry-list-options.html
 *
 * @param {object} params
 * @param {string[]} params.order
 * @param {string} params.contentTypeId
 * @param {string[]} params.fields
 * @param {number} params.linkDepth
 * @param {object} params.pageOptions
 * @param {number} params.pageOptions.pageSize
 * @param {number} params.pageOptions.pageIndex
 */
export const getEntries = async ({
  order,
  contentTypeId,
  fields,
  linkDepth = 3,
  pageOptions,
}) => {
  try {
    const result = await apiClient.entries.list({
      order,
      contentTypeId,
      fields,
      linkDepth,
      pageOptions,
      versionStatus: VERSION_STATUS,
    });
    return makeSuccessfulResponse(result);
  } catch (error) {
    return makeErrorResponse(error);
  }
};

/**
 * Fetch an entry using the sys.id
 * https://developer.zengenti.com/contensis/api/delivery/js/key-concepts/get-entry.html
 *
 * @param {object} params
 * @param {string} params.id
 * @param {string[]} params.fields
 * @param {number} params.linkDepth
 */
export const getEntry = async ({ id, fields, linkDepth = 3 }) => {
  try {
    const result = await apiClient.entries.get({
      id,
      fields,
      linkDepth,
      versionStatus: VERSION_STATUS,
    });
    return makeSuccessfulResponse(result);
  } catch (error) {
    return makeErrorResponse(error);
  }
};

/**
 * Makes a nice response object from the returned API payload
 *
 * @param {object} data
 * @returns {object} response object
 */
export const makeSuccessfulResponse = (data) => ({
  status: 200,
  data,
  message: null,
});

/**
 * Makes a nice error response from the caught API response
 * https://developer.zengenti.com/contensis/api/delivery/js/key-concepts/errors.html
 *
 * @param {Error} error The response error from the API
 */
export const makeErrorResponse = (error) => ({
  status: error.status,
  data: null,
  message: error.data.message,
});

/**
 * API wrapper for Contensis data
 *
 */
export class ContensisApi {
  constructor(store) {
    this.store = store;
    return this;
  }

  articles = async (pageSize = 50, pageIndex) => {
    const { articles, actions } = this.store;
    if (articles.length) return articles;
    await actions.setArticlesLoading(true);
    const result = await getEntries({
      contentTypeId: contentTypes.WELCOME_ARTICLE,
      pageOptions: {
        pageSize,
        pageIndex,
      },
    });
    if (result.status !== 200) {
      await actions.setArticlesLoading(false);
    } else {
      await actions.setArticlesPayload(result.data);
    }
    return result;
  };

  topics = async (pageSize = 50, pageIndex) => {
    const { topics, actions } = this.store;
    if (topics.length) return topics;
    await actions.setTopicsLoading(true);
    const result = await getEntries({
      contentTypeId: contentTypes.WELCOME_TOPIC,
      pageOptions: {
        pageSize,
        pageIndex,
      },
    });
    if (result.status !== 200) {
      await actions.setTopicsLoading(false);
    } else {
      await actions.setTopicsPayload(result.data);
    }
    return result;
  };

  footer = async () => {
    const { actions, footer } = this.store;
    if (footer) return footer;
    await actions.setFooterLoading(true);
    const result = await getEntries({
      contentTypeId: contentTypes.WELCOME_FOOTER_ITEMS,
    });
    if (result.status !== 200) {
      await actions.setFooterLoading(false);
    } else {
      const [footer] = result.data.items;
      await actions.setFooterPayload(footer);
    }
    return result;
  };

  homepageSplash = async () => {
    const { actions, homepageSplash } = this.store;
    if (homepageSplash) return homepageSplash;
    await actions.setHomesplashLoading(true);
    const result = await getEntries({
      contentTypeId: contentTypes.WELCOME_HOMEPAGE_SPLASH,
    });
    if (result.status !== 200) {
      await actions.setHomesplashLoading(false);
    } else {
      const [homepageSplash] = result.data.items;
      await actions.setHomesplashPayload(homepageSplash);
    }
    return result;
  };

  topicsSplash = async () => {
    const { actions, topics } = this.store;
    if (topics.length) return topics;
    await actions.setTopicsSplashLoading(true);
    const result = await getEntries({
      contentTypeId: contentTypes.WELCOME_LIST_PAGE_HEADER,
    });
    if (result.status !== 200) {
      await actions.setTopicsSplashLoading(false);
    } else {
      await actions.setTopicsSplashPayload(result.data);
    }
    return result;
  };

  socialMedia = async () => {
    const { actions, socialMedia } = this.store;
    if (socialMedia.length) return socialMedia;
    await actions.setSocialMediaLoading(true);
    const result = await getEntries({
      contentTypeId: contentTypes.WELCOME_SOCIAL_MEDIA,
    });
    if (result.status !== 200) {
      await actions.setSocialMediaLoading(false);
    } else {
      await actions.setSocialMediaPayload(result.data);
    }
    return result;
  };

  liveChatSettings = async () => {
    const { actions } = this.store;
    const result = await getEntries({
      contentTypeId: contentTypes.WELCOME_CHAT_CONTROL,
    });
    if (result.status !== 200) {
      //
    } else {
      const [liveChat] = result.data.items;
      await actions.setLiveChat(liveChat.displayChatOverlay);
    }
    return result;
  };

  /*   siteConfigSettings = async () => {
    const { actions } = this.store;
    const result = await getEntries({
      contentTypeId: contentTypes.WELCOME_SITE_CONFIG,
    });
    if (result.status !== 200) {
      //
    } else {
      await actions.setConfigSettings(result.data);
    }
    return result;
  }; */

  siteConfigSettings = async () => {
    const { actions } = this.store;
    const result = await getEntries({
      contentTypeId: contentTypes.WELCOME_SITE_CONFIG,
    });
    if (result.status !== 200) {
      //
    } else {
      const [siteConfig] = result.data.items;
      await actions.setConfigSettings(siteConfig);
    }
    return result;
  };

  welcomeBroadcast = async () => {
    const { actions, broadcasts } = this.store;
    if (broadcasts.length) return broadcasts;
    await actions.setBroadcastLoading(true);
    const result = await getEntries({
      contentTypeId: contentTypes.WELCOME_LIVE_BROADCAST,
    });
    if (result.status !== 200) {
      await actions.setBroadcastLoading(false);
    } else {
      await actions.setBroadcastPayload(result.data);
    }
    return result;
  };

  searchArticles = async ({ query, ...otherArgs }) => {
    return searchArticles({
      query,
      ...otherArgs,
    });
  };
}

/**
 * Context provider as a wrapper for the App
 *
 */
export const ApiProvider = ({ children }) => {
  const store = useStore();
  const api = new ContensisApi(store);
  return (
    <ApiContext.Provider value={{ api, ...store }}>
      {children}
    </ApiContext.Provider>
  );
};

/**
 * Custom hook to 'use' the API within any container/component
 *
 */
export const useApi = () => React.useContext(ApiContext);
