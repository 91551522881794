import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { formatHumanDayMin, formatHumanLong } from '../../utils/dates';

export const DateTime = styled(({ className, dateTime, children, ...props }) => (
  <time className={className} dateTime={dateTime} title={formatHumanLong(dateTime)} {...props}>
    {children ? children : formatHumanDayMin(dateTime)}
  </time>
))`
  color: ${({ theme }) => theme.colors.dantooine};
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.fonts.primary};
`;

DateTime.propTypes = {
  dateTime: PropTypes.string,
};
