import { posthtmlLocals } from "../posthtml.config";

export const meta = {
  title: posthtmlLocals.title,
  description: posthtmlLocals.description,
  keywords: posthtmlLocals.keywords,
  lang: posthtmlLocals.lang,
  version: posthtmlLocals.version,
  baseUrl: posthtmlLocals.baseURl,
  ogImage: posthtmlLocals.ogImage,
};

export const IS_PRODUCTION = process.env.NODE_ENV === "production";

export const baseUrl = posthtmlLocals.baseUrl;

export const lseBaseUrl = "https://www.lse.ac.uk";
