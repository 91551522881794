import React from 'react';
import styled from 'styled-components';

import { GRID_COL, GRID_GAP, SITE_WIDTH_MAX } from '../../theme';
import { containerStyles } from '../../styles';

import { ImgContainer, ImgInner, ImgComponent } from '../Img';
import { TitlePage } from '../Title';
import { FigureAsset } from '../Figure';
import { Blockquote } from '../Blockquote';
import { Callout } from '../Callout';
import { VideoContainer } from '../Video';
import { CardPrimary, CardSecondary, CardLink } from '../Card';
import { Ol, Ul } from '../List';

const colWidthPercent = (GRID_COL / SITE_WIDTH_MAX * 100);
const colGapPercent = (GRID_GAP / SITE_WIDTH_MAX * 100);

export const Page = styled.main`
  ${containerStyles}
  padding-top: 2rem;
  padding-bottom: 8rem;
`;

export const PageTopic = styled.article`

`;

export const PageArticle = styled.article`
  display: grid;
  grid-template-columns: repeat(12, ${colWidthPercent}%);
  grid-template-rows: 1fr auto;
  grid-column-gap: ${colGapPercent}%;

  ${({ theme }) => theme.media.bravo`
    display: block;
  `}
`;

export const PageArticleHeader = styled.header`
  margin-bottom: 6rem;

  ${TitlePage} {
    margin-bottom: 2.4rem;
  }
`;

export const PageArticleFooter = styled.footer`
  padding-top: 4rem;

  ${CardLink} {
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  ${CardSecondary} {
    max-width: none;
  }
`;

export const PageArticleContent = styled.section`
  grid-column: 1 / span 7;
  padding: 6rem 0 0;

  ${FigureAsset},
  ${Blockquote},
  ${Callout},
  ${VideoContainer} {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  ${Ol},
  ${Ul} {
    margin-bottom: 4rem;
  }

  ${({ theme }) => theme.media.alpha`
    grid-column: 1 / span 6;
  `}

  ${({ theme }) => theme.media.charlie`
    padding-top: 2rem;
  `}
`;

export const PageAside = styled.aside`
  padding: 8rem 0 0;
  grid-column: 9 / span 4;

  ${CardPrimary} {
    max-width: none;
  }

  ${({ theme }) => theme.media.alpha`
    grid-column: 8 / span 5;
  `}
`;

export const PageHeroImage = styled((props) => <FigureAsset {...props} />)`
  background-size: cover;
  background-color: ${({ theme }) => theme.colors.alderaan};

  ${ImgContainer},
  ${ImgInner} {
    height: 100%;
  }

  ${ImgComponent} {
    max-width: none;
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;
