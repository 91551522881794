import menu from './menu.svg';
import close from './close.svg';
import chevronRight from './chevron-right.svg';

export const ICONS = {
  menu,
  close,
  chevronRight,
};

export const ICON_NAMES = Object.keys(ICONS);
