/* eslint-disable no-unused-vars */
import React from 'react';
import { Link as ReachLink } from '@reach/router';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import routes from '../../routes';

const getProps = ({ isCurrent, isPartiallyCurrent, href }) => {
  const isHome = href === routes.HOME;
  const props = {};
  if (isPartiallyCurrent && !isHome) props['aria-current'] = true;
  if (isCurrent && !isHome) props['aria-current'] = 'page';
  return props;
}

export const Link = styled(({ className, href, css, ...props }) => {
  const external = href.match('https?:');
  const hashed = href.match(/^#/);
  const mailto = href.match(/^mailto:/);
  if (hashed || mailto) return <a className={className} href={href} {...props} />
  if (external) return <a className={className} href={href} target="_blank" rel="noreferrer noopener" {...props} />
  return <ReachLink className={className} to={href} {...props} getProps={getProps} />;
})`
  font-size: inherit;
  color: inherit;
  padding-bottom: 0.2rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.dantooine};
  text-decoration: none;
  transition: 0.2s ease;
  ${p => p.css}

  &:hover {
    color: ${({ theme }) => theme.colors.hoth};
  }

  &[href*="http"] {
    cursor: ne-resize;
  }

`;

Link.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
};

Link.defaultProps = {
  className: null,
  href: '',
};
