import { path } from 'ramda';
import { lseBaseUrl } from '../config';

export const getImageDataFromMediaObject = media => {
  const asset = path(['asset'], media);
  const altText = path(['altText'], media);
  const caption = path(['caption'], media);
  return {
    ...getImageDataFromAsset(asset),
    alt: altText,
    caption,
  };
};

export const getImageDataFromAsset = (asset = {}) => {
  const noAsset = { src: null, alt: null, };
  if (!asset) return noAsset;
  const uri = path(['sys', 'uri'], asset);
  const baseUris = path(['sys', 'baseUris'], asset) || [ lseBaseUrl ];
  const properties = path(['sys', 'properties'], asset) || {};
  const [ baseUri ] = baseUris;
  return {
    src: `${baseUri}${uri}`,
    alt: asset.altText,
    properties,
  };
}
