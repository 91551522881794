import dayjs from 'dayjs';
// import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import advancedFormat from 'dayjs/plugin/advancedFormat';

// dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);

export const FORMAT_DEFAULT = 'YYYY-MM-DDTHH:mm:ssZ';
export const FORMAT_HUMAN_LONG = 'dddd, Do MMMM YYYY, HH:mm';
export const FORMAT_HUMAN_SHORT = 'ddd, D MMM, HH:mm';
export const FORMAT_HUMAN_DAY_MIN = 'MMM D';


/**
 * Returns a formatted date string, check dayjs docs
 * @param {string} date
 * @param {string} format
 * @returns {string}
 */
export const formatDate = (date, format = FORMAT_DEFAULT) => dayjs(date).format(format);


/**
 * Format in human-readable, long format
 * @param {string} date
 * @return {string}
 */
export const formatHumanLong = date => dayjs(date).format(FORMAT_HUMAN_LONG);


/**
 * Format in human-readable, sort format
 * @param {string} date
 * @return {string}
 */
export const formatHumanShort = date => dayjs(date).format(FORMAT_HUMAN_SHORT);


/**
 * Format in human-readable, minimal day only
 * @param {string} date
 * @return {string}
 */
export const formatHumanDayMin = date => dayjs(date).format(FORMAT_HUMAN_DAY_MIN);


/**
 * Retuns a dayjs object for the current time
 * @param {string} format
 * @return {string}
 */
export const now = (format = FORMAT_DEFAULT) => dayjs().format(format);


/**
 * Returns the time from now
 * @param {string} date
 * @return {string}
 */
export const timeFromNow = date => dayjs(date).fromNow(false);


/**
 * Returns true if first date is before second date
 * @param {string} dateA
 * @param {string} dateB
 * @return {boolean}
 */
export const isBefore = (dateA, dateB) => dayjs(dateB).isBefore(dateA);

/**
 * Returns true if first date is after second date
 * @param {string} dateA
 * @param {string} dateB
 * @return {boolean}
 */
export const isAfter = (dateA, dateB) => dayjs(dateB).isAfter(dateA);

export const diff = (dateA, dateB, unit = 'seconds') => dayjs(dateA).diff(dayjs(dateB), unit);
