import * as Actions from "./actions";
import Immutable from "seamless-immutable";

import * as cache from "../cache";

export const DOMAIN_ARTICLES = "articles";
export const DOMAIN_TOPICS = "topics";
export const DOMAIN_FOOTER = "footer";
export const DOMAIN_SOCIALMEDIA = "socialmedia";
export const DOMAIN_SITECONFIG = "siteconfig";
export const DOMAIN_HOMESPLASH = "homesplash";
export const DOMAIN_TOPICSSPLASH = "topicssplash";
export const DOMAIN_CACHETIME = "cachetime";
export const DOMAIN_LIVECHAT = "livechat";
export const DOMAIN_BROADCAST = "broadcast";

export const withCache = (reducer) => {
  return (state, action) => {
    const newState = reducer(state, action);
    cache.save(newState);
    return newState;
  };
};

class EntriesModel {
  constructor() {
    return {
      loading: false,
      payload: null,
    };
  }
}

export const initialState = Immutable.from({
  [DOMAIN_TOPICS]: new EntriesModel(),
  [DOMAIN_ARTICLES]: new EntriesModel(),
  [DOMAIN_FOOTER]: new EntriesModel(),
  [DOMAIN_SOCIALMEDIA]: new EntriesModel(),
  [DOMAIN_SITECONFIG]: new EntriesModel(),
  [DOMAIN_HOMESPLASH]: new EntriesModel(),
  [DOMAIN_TOPICSSPLASH]: new EntriesModel(),
  [DOMAIN_BROADCAST]: new EntriesModel(),
  [DOMAIN_CACHETIME]: undefined,
  [DOMAIN_LIVECHAT]: false,
});

export const initialStateFromCache = () => {
  const cachedState = cache.load();
  return cachedState ? cachedState : initialState;
};

export const storeReducer = (state, action) => {
  const { type } = action;

  switch (type) {
    case Actions.ACTION_SET_ARTICLES_LOADING: {
      return state.setIn([DOMAIN_ARTICLES, "loading"], action.loading);
    }

    case Actions.ACTION_SET_ARTICLES_PAYLOAD: {
      return state.merge({
        ...state,
        [DOMAIN_ARTICLES]: {
          loading: false,
          payload: action.payload,
        },
      });
    }

    case Actions.ACTION_SET_TOPICS_LOADING: {
      return state.setIn([DOMAIN_TOPICS, "loading"], action.loading);
    }

    case Actions.ACTION_SET_TOPICS_PAYLOAD: {
      return state.merge({
        ...state,
        [DOMAIN_TOPICS]: {
          loading: false,
          payload: action.payload,
        },
      });
    }

    case Actions.ACTION_SET_FOOTER_LOADING: {
      return state.setIn([DOMAIN_FOOTER, "loading"], action.loading);
    }

    case Actions.ACTION_SET_FOOTER_PAYLOAD: {
      return state.merge({
        ...state,
        [DOMAIN_FOOTER]: {
          loading: false,
          payload: action.payload,
        },
      });
    }

    case Actions.ACTION_SET_SOCIALMEDIA_LOADING: {
      return state.setIn([DOMAIN_SOCIALMEDIA, "loading"], action.loading);
    }

    case Actions.ACTION_SET_SOCIALMEDIA_PAYLOAD: {
      return state.merge({
        ...state,
        [DOMAIN_SOCIALMEDIA]: {
          loading: false,
          payload: action.payload,
        },
      });
    }

    case Actions.ACTION_SET_SITECONFIG: {
      return state.setIn([DOMAIN_SITECONFIG], action.payload);
    }

    case Actions.ACTION_SET_HOMESPLASH_LOADING: {
      return state.setIn([DOMAIN_HOMESPLASH, "loading"], action.loading);
    }

    case Actions.ACTION_SET_HOMESPLASH_PAYLOAD: {
      return state.merge({
        ...state,
        [DOMAIN_HOMESPLASH]: {
          loading: false,
          payload: action.payload,
        },
      });
    }

    case Actions.ACTION_SET_TOPICSSPLASH_LOADING: {
      return state.setIn([DOMAIN_TOPICSSPLASH, "loading"], action.loading);
    }

    case Actions.ACTION_SET_TOPICSSPLASH_PAYLOAD: {
      return state.merge({
        ...state,
        [DOMAIN_TOPICSSPLASH]: {
          loading: false,
          payload: action.payload,
        },
      });
    }

    case Actions.ACTION_SET_BROADCAST_LOADING: {
      return state.setIn([DOMAIN_BROADCAST, "loading"], action.loading);
    }

    case Actions.ACTION_SET_BROADCAST_PAYLOAD: {
      return state.merge({
        ...state,
        [DOMAIN_BROADCAST]: {
          loading: false,
          payload: action.payload,
        },
      });
    }

    case Actions.ACTION_SET_LIVECHAT: {
      return state.setIn([DOMAIN_LIVECHAT], action.payload);
    }

    default:
      return state;
  }
};
