import React from 'react';
import styled from 'styled-components';

import { getImageDataFromMediaObject } from '../../utils/assets';

import { P } from '../Text';
import { Title } from '../Title';
import { Img, ImgInner, ImgComponent } from '../Img';

export const SplashContainer = styled.header``;

export const SplashInner = styled.div``;

export const SplashMessage = styled.div``;

export const SplashMessageTitle = styled(Title).attrs({
  tag: 'h1',
})`
  font-size: 3.2rem;
`;

export const SplashMessageDescription = styled(P)`
  font-size: 2rem;
`;

export const WelcomeSplash = styled(({ className, title, description, media }) => {
  const img = getImageDataFromMediaObject(media);
  return (
    <SplashContainer className={className}>
      <Img async src={img.src} alt={img.alt} />
      <SplashInner>
        <SplashMessage>
          <SplashMessageTitle>{title}</SplashMessageTitle>
          {description && <SplashMessageDescription>{description}</SplashMessageDescription>}
        </SplashMessage>
      </SplashInner>
    </SplashContainer>
  );
})`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${SplashInner} {
    position: relative;
    z-index: 1;
    padding: 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    height: auto;
    width: 100%;
  }

  ${SplashMessage} {
    width: 100%;
    max-width: 50%;
  }

  ${SplashMessageTitle} {
    margin-bottom: 1.6rem;
    display: inline-block;
    padding: 1rem 2rem;
    background-color: ${({ theme }) => theme.colors.coruscant};
    color: ${({ theme }) => theme.colors.naboo};

    &:only-child {
      margin-bottom: 0;
    }
  }
  ${SplashMessageDescription} {
    margin-bottom: 0;
    padding: 1rem 2rem;
    background-color: ${({ theme }) => theme.colors.coruscant};
    color: ${({ theme }) => theme.colors.naboo};
  }

  ${Img},
  ${ImgComponent} {
    position: absolute;
    top: 0;
    left: 0;
  }

  ${Img},
  ${ImgInner},
  ${ImgComponent} {
    height: 100%;
    width: 100%;
  }

  ${ImgComponent} {
    object-fit: cover;
  }

`;
