import React from 'react';
import styled from 'styled-components';

import { P } from '../Text';
import { containerStyles } from '../../styles';

export const FooterInner = styled.div``;

export const FooterSection = styled.section`

`;

export const Footer = styled(({ className, children, ...props }) => (
  <footer className={className} {...props}>
    <FooterInner>
      {children}
    </FooterInner>
  </footer>
))`
  padding: 4rem 0;
  background-color: ${({ theme }) => theme.colors.kashyyk};

  ${P} {
    margin-bottom: 0;
  }

  ${FooterInner} {

    ${containerStyles}

    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 2rem;

    button.ot-sdk-show-settings {
      font-size: inherit;
      color: inherit;
      border: 0px;
      background: none;
      padding: 0px;
      padding-bottom: 0.2rem;
      border-bottom: 1px solid #777777;
      -webkit-text-decoration: none;
      -webkit-text-decoration: none;
      text-decoration: none;
      -webkit-transition: 0.2s ease;
      -webkit-transition: 0.2s ease;
      transition: 0.2s ease;
      cursor: pointer;
      &:hover {
        color: #E0112B;
      }
  }

    ${({ theme }) => theme.media.charlie`
      grid-template-columns: 1fr;
    `}
  }

  ${FooterSection} {

  }
`;
