import React from 'react';
import styled, { css } from 'styled-components';
import { useMatch, useLocation, navigate } from '@reach/router';
import { parse } from 'query-string';

import routes, { makeRoute } from '../../routes';
import { containerStyles } from '../../styles';
import { useApi } from '../../api';
import { lockScrolling } from '../../utils/dom';
import { QUERY_KEY, useSearchQueryParam } from '../../utils/search';
import { ucFirst } from '../../utils/strings';

import { Icon } from '../Icon';
import { Logo } from '../Logo';
import { ButtonMenu, ButtonSearch } from '../Button';
import { Span, Em } from '../Text';
// import { Brand125 } from '../Brand125';
import { Breadcrumb, BreadcrumbLink } from '../../components/Breadcrumb';
import { Link } from '../../components/Link';
import { Ol, Li } from '../../components/List';
import { TitleSection } from '../../components/Title';
import { SearchForm, Input } from '../../components/Input';

export const getCurrentTopicCollectionFromMatches = () => {
  const { findTopicCollection } = useApi();
  const articleMatch = useMatch(routes.ARTICLE);
  const articlesMatch = useMatch(routes.ARTICLES);

  let topic = null;

  [articleMatch, articlesMatch].filter(Boolean).some(match => {
    if (match && match.topicId) {
      topic = findTopicCollection(match.topicId);
      return true;
    }
  });

  return topic;
}

export const getBreadcrumbLinks = (currentTopicCollection, menuOpen = false) => {
  const articleMatch = useMatch(routes.ARTICLE);
  const searchMatch = useMatch(routes.SEARCH);
  const articlesMatch = useMatch(routes.ARTICLES);
  const location = useLocation();
  const { findArticle } = useApi();

  const linkProps = {
    tabIndex: menuOpen ? -1 : 0,
  }

  const links = [
    <BreadcrumbLink key={routes.HOME} {...linkProps} to={routes.HOME}>Home</BreadcrumbLink>,
  ];

  if (currentTopicCollection) {
    const { topic } = currentTopicCollection;
    links.push(
      <BreadcrumbLink key={routes.ARTICLES} {...linkProps} to={makeRoute(topic.sys.slug)}>
        {topic.entryTitle}
      </BreadcrumbLink>
    );
  } else if (searchMatch) {
    const queryParams = parse(location.search);
    links.push(
      <BreadcrumbLink key={routes.SEARCH} {...linkProps} to={`${routes.SEARCH}${location.search}`}>
        <Span>Search: <Em>&quot;{queryParams[QUERY_KEY]}&quot;</Em></Span>
      </BreadcrumbLink>
    );
  } else if (articlesMatch) {
    links.push(
      <BreadcrumbLink key={routes.ARTICLES} {...linkProps} to={makeRoute(articlesMatch.topicId)}>
        {articlesMatch.topicId}
      </BreadcrumbLink>
    );
  } else if (articleMatch) {
    links.push(
      <BreadcrumbLink key={routes.ARTICLES} {...linkProps} to={makeRoute(articleMatch.topicId)}>
        {articleMatch.topicId}
      </BreadcrumbLink>
    );
  }

  if (articleMatch) {
    const article = findArticle(articleMatch.articleId);
    links.push(
      <BreadcrumbLink key={routes.ARTICLE} {...linkProps} to={makeRoute(articleMatch.topicId, articleMatch.articleId)}>
        {article ? ucFirst(article.articleTitle) : articleMatch.articleId}
      </BreadcrumbLink>
    );
  }

  return links

};

const NavLeft = styled.div``;

const NavRight = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(${p => React.Children.count(p.children)}, auto);
  grid-column-gap: 6.8rem;
  grid-template-rows: 1fr;
`;

export const NavMain = styled.div``;

export const NavFooter = styled.footer``;

export const NavFooterInner = styled.div``;

export const LogoLink = styled(Link)`
  display: block;
  padding: 0;
  border: none;
`;

export const NavMenuTitle = styled(TitleSection)`
  font-weight: 500;
  margin: 3rem 0 2rem;
  font-size: inherit;
`;

export const NavMenuOutside = styled.div``;
export const NavMenuInner = styled.div`
  padding-top: 11.2rem;
  padding-bottom: 12rem;
  color: ${({ theme }) => theme.colors.corusant};
  background-color: ${p => p.theme.colors.naboo};
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.2);
`;
export const NavMenuListWrapper = styled.div`
  ${containerStyles}
  font-size: 1.6rem;
  line-height: 1.7;
  text-align: right;
  display: flex;
  flex-direction: column;
`;
export const NavMenuList = styled(Ol)`
  list-style-type: none;
  padding-left: 0;
  font-size: inherit;
`;
export const NavMenuListItem = styled(Li)`
  margin-bottom: 1rem;
`;

export const NavMenu = styled(({
  open, // eslint-disable-line
  onClickOutside,
  children,
  ...props
}) => {
  const handleClickOutsideEvt = evt => {
    if (evt.target === evt.currentTarget) onClickOutside();
  }
  return (
    <NavMenuOutside {...props} onClick={handleClickOutsideEvt}>
      <NavMenuInner>
        {children}
      </NavMenuInner>
    </NavMenuOutside>
  );
})`
  position: fixed;
  z-index: 2;
  transition: 0.1s ease opacity;
  background-color: transparent;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  ${p => p.open ? css`
    opacity: 1;
    pointer-events: auto;
  ` : css`
    opacity: 0;
    pointer-events: none;
  `}

  ${Link} {
    padding: 0;
    border: 0;
    display: block;
    font-size: inherit;

    &[aria-current] {
      color: ${({ theme }) => theme.colors.hoth};
    }
  }

  ${({ theme }) => theme.media.charlie`
    ${NavMenuInner} {
      height: 100%;
      box-shadow: none;
      padding: 12rem 0 2rem;
    }

    ${NavMenuListWrapper} {
      height: 100%;
      justify-content: center;
      font-size: 2rem;
      text-align: center;
    }

    ${NavMenuListItem} {
      margin-bottom: 1.6rem;
    }

  `}
`;

export const NavInner = styled.div`
  background-color: ${p => p.theme.colors.naboo};
`;

export const Nav = styled(({ className }) => {
  const searchMatch = useMatch(routes.SEARCH);
  const location = useLocation();
  const queryValue = useSearchQueryParam();
  const [searchValue, setSearchValue] = React.useState(queryValue);
  const onSearchValueChange = ({ target }) => setSearchValue(target.value);

  const onSearchSubmit = (evt) => {
    evt.preventDefault();
    const { target } = evt;
    const { action } = target
    navigate(`${action}?${QUERY_KEY}=${searchValue}`);
  }

  // empty the search field when the page changes, except if the
  // page is the actual Search page
  React.useEffect(() => {
    if (!searchMatch) setSearchValue('');
    return () => {}
  }, [location.pathname]);

  const [menuOpen, setMenuOpen] = React.useState(false);
  const toggleMenuOpen = () => {
    lockScrolling(!menuOpen);
    setMenuOpen(!menuOpen);
  }
  const closeMenu = () => {
    lockScrolling(false);
    setMenuOpen(false);
  }

  const { topicCollections } = useApi();
  const currentTopicCollection = getCurrentTopicCollectionFromMatches();
  const links = getBreadcrumbLinks(currentTopicCollection, menuOpen);

  React.useEffect(() => {
    return closeMenu;
  }, [window.location.pathname]);

  return (
    <nav className={className}>
      <NavInner>
        <NavMain>
          <NavLeft>
            <LogoLink href={routes.HOME}><Logo /></LogoLink>
          </NavLeft>
          <NavRight>
            {/* <Brand125 /> */}
            <ButtonMenu onClick={toggleMenuOpen}>
              <Icon icon={menuOpen ? 'close' : 'menu'} />
              Menu
            </ButtonMenu>
          </NavRight>
        </NavMain>
        <NavFooter>
          <NavFooterInner>
            <Breadcrumb>{links}</Breadcrumb>
            <SearchForm onSubmit={onSearchSubmit}>
              <Input name={QUERY_KEY} placeholder="Search..." value={searchValue} onChange={onSearchValueChange} />
              <ButtonSearch type="submite">Go</ButtonSearch>
            </SearchForm>
          </NavFooterInner>
        </NavFooter>
      </NavInner>
      <NavMenu open={menuOpen} onClickOutside={closeMenu}>
        <NavMenuListWrapper>
          <Link tabIndex={menuOpen ? 0 : -1} href={routes.HOME}>Home</Link>
          <NavMenuTitle>Take me to&hellip;</NavMenuTitle>
          <NavMenuList>
            {topicCollections.map(({ topic }) => (
              <NavMenuListItem key={topic.sys.id}>
                <Link
                  tabIndex={menuOpen ? 0 : -1}
                  href={makeRoute(topic.sys.slug)}
                >
                  {topic.entryTitle}
                </Link>
              </NavMenuListItem>
            ))}
          </NavMenuList>
        </NavMenuListWrapper>
      </NavMenu>
    </nav>
  );
})`
  ${NavMain} {
    ${containerStyles}
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 3;
  }

  ${NavFooter} {
    background-color: ${({ theme }) => theme.colors.kashyyk};
  }
  ${NavFooterInner} {
    ${containerStyles}
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  ${SearchForm} {
    align-items: baseline;
    flex: 1 1 34rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  ${Breadcrumb} {
    margin-right: 2rem;
    width: auto;
    flex: 3 1 auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  ${Logo} {

  }

`;
