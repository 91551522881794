import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { contentTypes, getEntry } from '../../api';
import {Page} from '../../components/Page';
import Article from '../Article';
import Articles from '../Articles';
import Home from '../Home';

const renderTemplate = (entry) => {
    const contentTypeId = entry.sys.contentTypeId;

    switch (contentTypeId) {
        case contentTypes.WELCOME_HOMEPAGE_SPLASH: return <Home />;
        case contentTypes.WELCOME_TOPIC: return <Articles topicId={entry.sys.slug} />;
        case contentTypes.WELCOME_ARTICLE: return <Article articleId={entry.sys.slug}/>;
        default: return <div>No template found for content type id {contentTypeId}</div>
    }
}

const Preview = ({ guid }) => {
    const [_entry, _setEntry] = useState(null);
    const [_error, _setError] = useState(null);
    const [_isLoading, _setIsLoading] = useState(true);

    useEffect(()=> {
        const getEntryFromGuid = async(guid) => {
            const res = await getEntry({id: guid, fields: ['sys.slug', 'sys.contentTypeId']});
            if(res.status === 200 && res.data) {
                _setEntry(res.data);
                _setIsLoading(false);
            }
            else {
                _setError(`Entry with id ${guid} could not be found`);
                _setIsLoading(false);
            }
        }

        getEntryFromGuid(guid);
    }, [guid]);

    return <>
        <Helmet>
            <meta name="robots" content="noindex nofollow" />
        </Helmet>
        {_isLoading ? <Page>...loading entry</Page> : _entry ? renderTemplate(_entry) : <Page>{_error}</Page>}
    </>;
};

export default Preview;
