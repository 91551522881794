import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useParams } from '@reach/router';
import { path } from 'ramda';

import { useApi } from '../../api';
import { makeRoute } from '../../routes';
import { getCardProps, findPrevNextArticles, findRelatedArticles } from '../../utils/articles';
import { wordLimit } from '../../utils/strings';
import { getImageDataFromMediaObject } from '../../utils/assets';

import { Helmet } from '../../components/Helmet';
import { useScrollToTop } from '../../components/ScrollToTop';
import { TitlePage, TitleSection } from '../../components/Title';
import { Lede } from '../../components/Text';
import { Blockquote } from '../../components/Blockquote';
import { Callout } from '../../components/Callout';
import { Markdown } from '../../components/Markdown';
import { FigureAsset } from '../../components/Figure';
import { Page, PageArticle, PageArticleContent, PageArticleHeader,
  PageAside, PageHeroImage, PageArticleFooter } from '../../components/Page';
import { CardSecondary, CardLink } from '../../components/Card';
import { VideoYouTube } from '../../components/Video';
import { SkeletonArticleContent,
  SkeletonArticleSidebar, SkeletonHeroImage } from '../../components/Skeleton';

const RelatedList = styled.ol`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const RelatedItem = styled.li``;

const SidebarRight = styled(PageAside)`
  ${TitleSection} {
    margin-bottom: 2.4rem;
  }
  ${RelatedItem} {
    margin-bottom: 2.4rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const SubheadingTitle = styled(TitleSection)`
  margin-bottom: 4rem;
`;

const RelatedArticleTitle = styled(TitleSection)`
  font-size: 2.4rem;
`;

export const contentKey = (type, level, item) => `content-${level}-${type}-${item}`;

// text types are meant to be subheadings
export const renderTypeText = (values, l) =>
  values.map((value, i) =>
    <SubheadingTitle key={contentKey('text', l, i)}>{value}</SubheadingTitle>);

export const renderTypeQuote = (values, l) =>
  values.map(({ text, source }, i) =>
    <Blockquote key={contentKey('quote', l, i)} cite={source}>{text}</Blockquote>);

export const renderTypeMarkup = (values, l) =>
  values.map((value, i) =>
    <div key={contentKey('markup', l, i)}>
      <Markdown source={value} />
    </div>);

export const renderTypeImage = (values, l) =>
  values.map((media, i) =>
    <FigureAsset key={contentKey('img', l, i)} media={media} />);


export const renderTypeCallout = ({ callout }, level) =>
  <Callout key={`content-${level}-callout`}>
    <Markdown source={callout} />
  </Callout>;

export const renderTypeVideoStreams = (values, level) => values.map(({ youTubeLink }, i) => {
  if (youTubeLink) return <VideoYouTube key={`content-${level}-video-${i}`} src={youTubeLink} />;
  return null;
});

export const renderContent = (content = []) => {
  return content.map(({ type, value }, level) => {
    if (value === null) return null;
    switch(type) {
      case 'text':
        return renderTypeText(value, level);
      case 'quote':
        return renderTypeQuote(value, level);
      case 'markup':
        return renderTypeMarkup(value, level);
      case 'welcomeArticleLinkCallout':
        return renderTypeCallout(value, level);
      case 'image':
        return renderTypeImage(value, level);
      case 'streamLinks':
        return renderTypeVideoStreams(value, level);
      default:
        return null;
    }
  }).filter(Boolean);
}

export const renderMainImage = ({ mainImage } = {}) => {
  if (!mainImage) return null;
  if (!mainImage) return null;
  return <PageHeroImage media={mainImage} />
};

export const renderPageMeta = (topicCollection, article) => {
  if (!topicCollection || !article) return null;
  const asset = getImageDataFromMediaObject(path(['thumbnail'], article));
  const topicTitle = path(['topic', 'entryTitle'], topicCollection);
  const articleTitle = path(['articleTitle'], article);
  const articleDescription = path(['subtitle'], article);
  return (
    <Helmet title={[articleTitle, topicTitle]}>
      <meta name="description" content={articleDescription} />
      {asset.src && <meta property="og:image" content={asset.src} />}
      {asset.properties && <meta property="og:image:width" content={asset.properties.width} />}
      {asset.properties && <meta property="og:image:height" content={asset.properties.height} />}
    </Helmet>
  );
}

export const Article = ({ className, articleId }) => {
  useScrollToTop();
  const params = useParams();

  const { articles, articlesLoading, findTopicCollection, topicCollectionsLoading } = useApi();

  const article = articles.find(({ sys }) => sys.slug === articleId);
  const topicCollection = findTopicCollection(params.topicId, articleId);

  const relatedArticles = findRelatedArticles(topicCollection, articleId || params.articleId);
  const [ previousArticle, nextArticle ] = findPrevNextArticles(topicCollection, articleId || params.articleId);

  return (
    <Page className={className}>
      {renderPageMeta(topicCollection, article)}
      <PageArticle>

        {articlesLoading ? <SkeletonHeroImage /> : renderMainImage(article)}

        <PageArticleContent>
          {articlesLoading ? <SkeletonArticleContent /> : (
            <React.Fragment>
              <PageArticleHeader>
              <TitlePage>{article.articleTitle}</TitlePage>
              <Lede>{article.subtitle}</Lede>
            </PageArticleHeader>

            {renderContent(article.contentBlob)}

            {(previousArticle || nextArticle) && (
              <PageArticleFooter>
                <RelatedArticleTitle tag="h4">Suggested article{(previousArticle && nextArticle) && 's'}</RelatedArticleTitle>

                {previousArticle && (
                  <CardLink rel="prev" href={makeRoute(topicCollection.topic.sys.slug, previousArticle.sys.slug)}>
                    <CardSecondary { ...getCardProps(previousArticle) } description={null} />
                  </CardLink>
                )}

                {nextArticle && (
                  <CardLink rel="next" href={makeRoute(topicCollection.topic.sys.slug, nextArticle.sys.slug)}>
                    <CardSecondary { ...getCardProps(nextArticle) } description={null} />
                  </CardLink>
                )}
              </PageArticleFooter>
            )}
            </React.Fragment>
          )}
        </PageArticleContent>
        <SidebarRight>
          {topicCollectionsLoading ? <SkeletonArticleSidebar /> : (
            <React.Fragment>
              <RelatedArticleTitle>Related articles</RelatedArticleTitle>
              <RelatedList>
                {relatedArticles.map(topicArticle => {

                  const cardProps = getCardProps(topicArticle);
                  cardProps.subtitle = wordLimit(cardProps.subtitle, 10);

                  return (
                    <RelatedItem key={topicArticle.sys.id}>
                      <CardLink href={makeRoute(topicCollection.topic.sys.slug, topicArticle.sys.slug)}>
                        <CardSecondary { ...cardProps } description={null} />
                      </CardLink>
                    </RelatedItem>
                  );
                })}
              </RelatedList>
            </React.Fragment>
          )}
        </SidebarRight>
      </PageArticle>
    </Page>
  );
}

Article.propTypes = {
  articleId: PropTypes.string.isRequired,
};

Article.defaultProps = {

};

export default styled(Article)`
  padding-top: 0;

  ${PageArticle} {
    margin-top: 61rem;
    padding-bottom: 0;

    @media screen and (min-width: 992px) {
      margin-top: 30vh;
    }

    ${PageHeroImage},
    ${SkeletonHeroImage} {
      position: absolute;
      top: 16rem;
      left: 0;
      z-index: -1;
      height: 61rem;
      width: 100%;

      @media screen and (min-width: 992px) {
        height: 30vh;
      }
    }
  }

  ${({ theme }) => theme.media.alpha`
    ${PageArticle} {
      margin-top: 61vw;

      ${PageHeroImage},
      ${SkeletonHeroImage} {
        height: 61vw;
      }
    }
  `}
`;
