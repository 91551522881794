import styled from 'styled-components';

import {
  CardPrimary,
  CardPrimaryInner,
  CardPrimaryFigure,
  CardPrimaryContent,
  CardPrimaryContentBody,
  CardPrimaryDescription,
  CardPrimaryContentFooter,
} from '../CardPrimary';

export const CardSecondary = styled(CardPrimary)`
  max-width: 36rem;
  background: ${({ theme }) => theme.colors.naboo};
  color: ${({ theme }) => theme.colors.coruscant};
  border: 1px solid ${({ theme }) => theme.colors.alderaan};

  ${CardPrimaryInner} {
    flex-direction: row-reverse;
  }
  ${CardPrimaryFigure} {
    width: 100%;
    flex: 0 1 50%;
    padding-bottom: 0;
    height: auto;
  }
  ${CardPrimaryContentBody} {
    justify-content: flex-start;
    margin-bottom: 2rem;
  }
  ${CardPrimaryContent} {
    flex: 0 1 50%;
    padding: 2.4rem 1.6rem 1.6rem;
  }
  ${CardPrimaryDescription} {
    &:empty {
      display: none;
    }
  }
  ${CardPrimaryContentFooter} {
    color: ${({ theme }) => theme.colors.dantooine};
  }
`;

const propTypes = {...CardPrimary.propTypes};

CardSecondary.propTypes = propTypes;

CardSecondary.displayName = 'CardSecondary';
