import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const Title = styled(({ tag: HTag, ...props }) => <HTag {...props} />)`
  margin: 0 0 1em;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: inherit;
  line-height: inherit;
`;

Title.propTypes = {
  tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
};

Title.defaultProps = {
  tag: 'h3',
};


export const TitlePage = styled(Title).attrs({
  tag: 'h1',
})`
  font-size: 4.8rem;
  line-height: 1.14;
`;

export const TitleSection = styled(Title).attrs({
  tag: 'h3',
})`
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 500;
`;
