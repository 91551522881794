export const ACTION_SET_ARTICLES_PAYLOAD = "ACTION_SET_ARTICLES_PAYLOAD";
export const ACTION_SET_ARTICLES_LOADING = "ACTION_SET_ARTICLES_LOADING";

export const ACTION_SET_TOPICS_PAYLOAD = "ACTION_SET_TOPICS_PAYLOAD";
export const ACTION_SET_TOPICS_LOADING = "ACTION_SET_TOPICS_LOADING";

export const ACTION_SET_FOOTER_PAYLOAD = "ACTION_SET_FOOTER_PAYLOAD";
export const ACTION_SET_FOOTER_LOADING = "ACTION_SET_FOOTER_LOADING";

export const ACTION_SET_SOCIALMEDIA_PAYLOAD = "ACTION_SET_SOCIALMEDIA_PAYLOAD";
export const ACTION_SET_SOCIALMEDIA_LOADING = "ACTION_SET_SOCIALMEDIA_LOADING";

export const ACTION_SET_HOMESPLASH_PAYLOAD = "ACTION_SET_HOMESPLASH_PAYLOAD";
export const ACTION_SET_HOMESPLASH_LOADING = "ACTION_SET_HOMESPLASH_LOADING";

export const ACTION_SET_TOPICSSPLASH_PAYLOAD =
  "ACTION_SET_TOPICSSPLASH_PAYLOAD";
export const ACTION_SET_TOPICSSPLASH_LOADING =
  "ACTION_SET_TOPICSSPLASH_LOADING";

export const ACTION_SET_BROADCAST_PAYLOAD = "ACTION_SET_BROADCAST_PAYLOAD";
export const ACTION_SET_BROADCAST_LOADING = "ACTION_SET_BROADCAST_LOADING";

export const ACTION_SET_COOKIES = "ACTION_SET_COOKIES";
export const ACTION_SET_LIVECHAT = "ACTION_SET_LIVECHAT";

export const ACTION_SET_SITECONFIG = "ACTION_SET_SITECONFIG";

export const setArticlesLoading = async (dispatch, loading = false) => {
  await dispatch({
    type: ACTION_SET_ARTICLES_LOADING,
    loading,
  });
};

export const setArticlesPayload = async (dispatch, payload) => {
  await dispatch({
    type: ACTION_SET_ARTICLES_PAYLOAD,
    payload,
  });
};

export const setTopicsLoading = async (dispatch, loading = false) => {
  await dispatch({
    type: ACTION_SET_TOPICS_LOADING,
    loading,
  });
};

export const setTopicsPayload = async (dispatch, payload) => {
  await dispatch({
    type: ACTION_SET_TOPICS_PAYLOAD,
    payload,
  });
};

export const setFooterLoading = async (dispatch, loading = false) => {
  await dispatch({
    type: ACTION_SET_FOOTER_LOADING,
    loading,
  });
};

export const setFooterPayload = async (dispatch, payload) => {
  await dispatch({
    type: ACTION_SET_FOOTER_PAYLOAD,
    payload,
  });
};

export const setSocialMediaLoading = async (dispatch, loading = false) => {
  await dispatch({
    type: ACTION_SET_SOCIALMEDIA_LOADING,
    loading,
  });
};

export const setSocialMediaPayload = async (dispatch, payload) => {
  await dispatch({
    type: ACTION_SET_SOCIALMEDIA_PAYLOAD,
    payload,
  });
};

export const setHomesplashLoading = async (dispatch, loading = false) => {
  await dispatch({
    type: ACTION_SET_HOMESPLASH_LOADING,
    loading,
  });
};

export const setHomesplashPayload = async (dispatch, payload) => {
  await dispatch({
    type: ACTION_SET_HOMESPLASH_PAYLOAD,
    payload,
  });
};

export const setTopicsSplashLoading = async (dispatch, loading = false) => {
  await dispatch({
    type: ACTION_SET_TOPICSSPLASH_LOADING,
    loading,
  });
};

export const setTopicsSplashPayload = async (dispatch, payload) => {
  await dispatch({
    type: ACTION_SET_TOPICSSPLASH_PAYLOAD,
    payload,
  });
};

export const setBroadcastLoading = async (dispatch, loading = false) => {
  await dispatch({
    type: ACTION_SET_BROADCAST_LOADING,
    loading,
  });
};

export const setBroadcastPayload = async (dispatch, payload) => {
  await dispatch({
    type: ACTION_SET_BROADCAST_PAYLOAD,
    payload,
  });
};

export const setLiveChat = async (dispatch, payload) => {
  await dispatch({
    type: ACTION_SET_LIVECHAT,
    payload,
  });
};

export const setConfigSettings = async (dispatch, payload) => {
  await dispatch({
    type: ACTION_SET_SITECONFIG,
    payload,
  });
};

export const makeActionsWithDispatch = (dispatch) => ({
  setArticlesLoading: (...args) => setArticlesLoading(dispatch, ...args),
  setArticlesPayload: (...args) => setArticlesPayload(dispatch, ...args),

  setTopicsLoading: (...args) => setTopicsLoading(dispatch, ...args),
  setTopicsPayload: (...args) => setTopicsPayload(dispatch, ...args),

  setFooterLoading: (...args) => setFooterLoading(dispatch, ...args),
  setFooterPayload: (...args) => setFooterPayload(dispatch, ...args),

  setSocialMediaLoading: (...args) => setSocialMediaLoading(dispatch, ...args),
  setSocialMediaPayload: (...args) => setSocialMediaPayload(dispatch, ...args),

  setHomesplashLoading: (...args) => setHomesplashLoading(dispatch, ...args),
  setHomesplashPayload: (...args) => setHomesplashPayload(dispatch, ...args),

  setTopicsSplashLoading: (...args) =>
    setTopicsSplashLoading(dispatch, ...args),
  setTopicsSplashPayload: (...args) =>
    setTopicsSplashPayload(dispatch, ...args),

  setBroadcastLoading: (...args) => setBroadcastLoading(dispatch, ...args),
  setBroadcastPayload: (...args) => setBroadcastPayload(dispatch, ...args),

  setLiveChat: (...args) => setLiveChat(dispatch, ...args),

  setConfigSettings: (...args) => setConfigSettings(dispatch, ...args),
});
