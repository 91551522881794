import React from 'react';
import styled from 'styled-components';
import ISvg from 'react-inlinesvg';

import logoSrc from './logo.svg';
import logoSquareSrc from './logo-square.svg';

export const Svg = styled(ISvg)`
  /* ensures svgs are full size of the wrapping span */
  display: flex;
  height: 100%;
  width: 100%;

  .square {
    fill: ${({ theme }) => theme.colors.hoth};
  }

  .letters {
    fill: ${({ theme }) => theme.colors.naboo};
  }

  .text {
    fill: ${({ theme }) => theme.colors.tatooine};
  }
`;

export const Logo = styled(({ className, ...props }) => (
  <div className={className} {...props}>
    <Svg src={logoSrc} />
  </div>
))`
  width: 18rem;
  height: 6.4rem;
`;

export const LogoSquare = styled(({ className, ...props }) => (
  <div className={className} {...props}>
    <Svg src={logoSquareSrc} />
  </div>
))`
  width: 6.4rem;
`;
