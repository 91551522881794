import React from 'react';
import styled from 'styled-components';
import { parse } from 'query-string';

export const VideoContainer = styled.div`

`;

export const VideoComponent = styled.video`

`;

export const Video = styled(({ className, ...props }) => (
  <VideoContainer className={className}>
    <VideoComponent {...props} />
  </VideoContainer>
))`
  height: 0;
  position: relative;
  padding-bottom: 56.25%;

  ${VideoComponent} {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

export const YouTubeIframe = styled.iframe.attrs({
  frameBorder: '0',
 })``;

export const sanitizeSrc = (src = '') => {
  if (src.match('embed')) return src;
  const [ _href, query ] = src.split('?'); // eslint-disable-line
  const { v = null, t } = parse(query);
  if (!v) return '';
  const params = [];
  if (t) params.push(`start=${parseInt(t, 10)}`);
  return `https://youtube.com/embed/${v}?${params.join('&')}`;
}

export const VideoYouTube = styled(({ className, src, ...props }) => (
  <VideoContainer className={className}>
    <YouTubeIframe src={sanitizeSrc(src)} {...props} />
  </VideoContainer>
))`
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;

  ${YouTubeIframe} {
    position: absolute;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    left: -1px;
    top: -1px;
  }
`;
