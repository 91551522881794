/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';

export const P = styled(({ css, ...props }) => <p {...props} />)`
  margin: 0 0 2rem;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 1.6rem;
  line-height: 2;
  ${p => p.css}
`;

export const Lede = styled(P)`
  font-size: 2rem;
  line-height: 1.6;
  margin: 0;
`;

export const Span = styled(({ css, ...props }) => <span {...props} />)`
  ${p => p.css}
`;

export const Strong = styled(({ css, ...props }) => <strong {...props} />)`
  ${p => p.css}
`;

export const Em = styled(({ css, ...props }) => <em {...props} />)`
  ${p => p.css}
`;

export const Small = styled(({ css, ...props }) => <small {...props} />)`
  ${p => p.css}
`;
