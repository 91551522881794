import React from 'react';
import { ThemeProvider as StyledThemeProvider, css } from 'styled-components';

export const colors = {
  hoth: '#E0112B',
  tatooine: '#000000',
  naboo: '#ffffff',
  dantooine: '#777777',
  coruscant: '#3A3D3F',
  alderaan: '#ECECEC',
  kashyyk: '#F5F5F5',
  bespin: '#c6c6c6',
};

export const fonts = {
  primary: 'Roboto, sans-serif',
  serif: 'Libre Baskerville, serif',
};

export const GRID_COL = 78; // px
export const GRID_GAP = 24; // px
export const GRID_COL_COUNT = 12;

export const SITE_WIDTH_MAX = (GRID_COL * GRID_COL_COUNT) + (GRID_GAP * (GRID_COL_COUNT - 1));


export const dimensions = {
  sitePadding: '4rem',
  siteWidth: `${(SITE_WIDTH_MAX / 10) + 4}rem`, // site width + padding
};

// https://www.styled-components.com/docs/advanced#media-templates
// Can use like: media.nav`...`
// x10 for px equivalent
const remToPxRatio = 0.625;
const mediaSizes = {
  alpha: 120,
  bravo: 80,
  charlie: 60,
};

export const media = Object.keys(mediaSizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media screen and (max-width: ${mediaSizes[label] * remToPxRatio}rem) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

export const makeMedia = width => (...args) => css`
  @media screen and (max-width: ${width}rem) {
    ${css(...args)}
  }
`;

export const theme = {
  fonts,
  colors,
  dimensions,
  media,
  makeMedia,
};

export const ThemeProvider = (props) =>
  <StyledThemeProvider theme={theme} {...props} />
