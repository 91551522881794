import { getImageDataFromMediaObject } from './assets';

export const articleSorterFn = (articleA, articleB) => {
  const dateA = articleA.sys.version.modified;
  const dateB = articleB.sys.version.modified;

  const priorityA = articleA.topPriority;
  const priorityB = articleB.topPriority;

  if (priorityA < priorityB) return 1;
  if (priorityA > priorityB) return -1;

  if (dateA < dateB) return 1;
  if (dateA > dateB) return -1;

  return 0;
};

export const sortArticles = (articles = []) => [...articles].sort(articleSorterFn);

export const displayOrderSortedFn = (itemA, itemB) => {
  const orderA = itemA.displayOrder;
  const orderB = itemB.displayOrder;

  if (orderA < orderB) return -1;
  if (orderA > orderB) return 1;

  return 0;
};

export const sortByDisplayOrder = (items = []) => [...items].sort(displayOrderSortedFn);

export const filterArticlesbyWelcomeCategory = (articles = [], category = '__NO_CATEGORY__') =>
  articles.filter(({ welcomeCategory }) =>
    welcomeCategory &&
    welcomeCategory.welcomeCategoryList &&
    welcomeCategory.welcomeCategoryList.length &&
    welcomeCategory.welcomeCategoryList.includes(category)
  );

export const findArticleBySlug = (articleSlug = '__NO_SLUG__', articles = []) =>
  articles.find(article => article.sys.slug === articleSlug);

export const findtopicSplash = (splashes = [], topicCategory = '__NO_CATEGORY__') =>
  splashes.find(({ welcomeCategory }) =>
    welcomeCategory &&
    welcomeCategory.welcomeCategoryList &&
    welcomeCategory.welcomeCategoryList.length &&
    welcomeCategory.welcomeCategoryList.includes(topicCategory)
  );


export const topicsSorterFn = (topicA, topicB) => {

  const priorityA = topicA.homePageSortOrder;
  const priorityB = topicB.homePageSortOrder;

  if (priorityA < priorityB) return -1;
  if (priorityA > priorityB) return 1;

  return 0;
};

export const sortTopics = (topics = []) => [...topics].sort(topicsSorterFn);

export const findTopicCollectionBySlug = (topicSlug = '__NO_SLUG__', topicCollections = []) =>
  topicCollections.find(({ topic }) => topic.sys.slug === topicSlug);

export const findTopicCollectionByArticleId = (articleId, topicCollections = []) =>
  topicCollections.find(({articles}) => articles?.find(article => article.sys.slug === articleId));

export const getCardProps = ({
  subtitle,
  articleTitle,
  introductoryText,
  thumbnail,
}) => {
  const img = getImageDataFromMediaObject(thumbnail);
  return {
    title: articleTitle,
    description: introductoryText,
    subtitle: subtitle,
    imgAlt: img.alt,
    imgSrc: img.src,
  }
}

export const findTopicSlugByCategory = (topicCategory = '__NO_CATEGORY__', slugCategoryMapping = []) => {
  const keyPair = slugCategoryMapping.find(({ category }) => category === topicCategory);
  return keyPair ? keyPair.slug : undefined;
}

export const findRelatedArticles = (topicCollection, articleId = '__NO_ARTICLE_ID__') => {
  if (!topicCollection) return [];
  return topicCollection.articles
    .filter(topicArticle => topicArticle.sys.slug !== articleId);
}

export const findPrevNextArticles = (topicCollection, articleId = '__NO_ARTICLE_ID__') => {
  if (!topicCollection) return [null, null];
  const { articles } = topicCollection;
  const currentArticleIndex = articles
    .findIndex(topicArticle => topicArticle.sys.slug === articleId);
  return [
    articles[currentArticleIndex - 1],
    articles[currentArticleIndex + 1]
  ];
}
