import { createSelector } from "reselect";

import {
  DOMAIN_ARTICLES,
  DOMAIN_TOPICS,
  DOMAIN_FOOTER,
  DOMAIN_SITECONFIG,
  DOMAIN_HOMESPLASH,
  DOMAIN_TOPICSSPLASH,
  DOMAIN_SOCIALMEDIA,
  DOMAIN_COOKIES,
  DOMAIN_BROADCAST,
  DOMAIN_LIVECHAT,
} from "./reducers";
import {
  sortArticles,
  filterArticlesbyWelcomeCategory,
  sortTopics,
  findTopicCollectionBySlug,
  findTopicCollectionByArticleId,
  findArticleBySlug,
  findtopicSplash,
  sortByDisplayOrder,
  findTopicSlugByCategory,
} from "../utils/articles";

export const topicsDomainSelector = (state) => state[DOMAIN_TOPICS];
export const articlesDomainSelector = (state) => state[DOMAIN_ARTICLES];
export const homepageSplashDomainSelector = (state) => state[DOMAIN_HOMESPLASH];
export const siteConfigDomainSelector = (state) => state[DOMAIN_SITECONFIG];
export const topicsSplashDomainSelector = (state) => state[DOMAIN_TOPICSSPLASH];
export const footerDomainSelector = (state) => state[DOMAIN_FOOTER];
export const socialMediaDomainSelector = (state) => state[DOMAIN_SOCIALMEDIA];
export const cookiesDomainSelector = (state) => state[DOMAIN_COOKIES];
export const liveChatDomainSelector = (state) => state[DOMAIN_LIVECHAT];
export const broadcastDomainSelector = (state) => state[DOMAIN_BROADCAST];

export const payloadItemsSelector = ({ payload }) =>
  payload ? payload.items : [];
export const loadingSelector = ({ loading }) => loading;

export const topicsSelector = createSelector(
  topicsDomainSelector,
  payloadItemsSelector
);

export const topicsSortedSelector = createSelector(topicsSelector, sortTopics);

export const topicsLoadingSelector = createSelector(
  topicsDomainSelector,
  loadingSelector
);

export const articlesSelector = createSelector(
  articlesDomainSelector,
  payloadItemsSelector
);

export const articleIdsSelector = createSelector(articlesSelector, (articles) =>
  articles.map(({ sys }) => sys.id)
);

export const articlesSortedSelector = createSelector(
  articlesSelector,
  sortArticles
);

export const topicsSplashSelector = createSelector(
  topicsSplashDomainSelector,
  payloadItemsSelector
);

export const topicsSplashLoadingSelector = createSelector(
  topicsSplashDomainSelector,
  loadingSelector
);

export const topicCollectionsSelector = createSelector(
  topicsSortedSelector,
  articlesSortedSelector,
  topicsSplashSelector,
  (topicsSorted, articlesSorted, topicsSplashes) => {
    const topicArticleCollections = topicsSorted.map((topic) => {
      // could do array intersection when filtering articles
      const [welcomeCategory] = topic.welcomeCategory.welcomeCategoryList;
      const articles = filterArticlesbyWelcomeCategory(
        articlesSorted,
        welcomeCategory
      );
      const splash = findtopicSplash(topicsSplashes, welcomeCategory);
      return { topic, articles, splash };
    });
    return topicArticleCollections.filter(
      (topicCollection) => topicCollection.articles.length
    );
  }
);

export const topicsSlugCategoryMappedSelector = createSelector(
  topicsSelector,
  (topics) => {
    return topics.map(({ sys, welcomeCategory }) => ({
      slug: sys.slug,
      category: welcomeCategory.welcomeCategoryList[0],
    }));
  }
);

export const articlesLoadingSelector = createSelector(
  articlesDomainSelector,
  articlesSelector,
  ({ loading }, articles) => loading || (!loading && !articles.length)
);

export const topicCollectionsLoadingSelector = createSelector(
  topicCollectionsSelector,
  topicsLoadingSelector,
  articlesLoadingSelector,
  (topicCollections, topicsLoading, articlesLoading) =>
    topicsLoading ||
    articlesLoading ||
    (!articlesLoading && !topicCollections.length)
);

export const siteConfigSelector = createSelector(
  siteConfigDomainSelector,
  (siteConfig) => siteConfig
);

export const homepageSplashSelector = createSelector(
  homepageSplashDomainSelector,
  ({ payload }) => payload
);

export const homepageSplashLoadingSelector = createSelector(
  homepageSplashDomainSelector,
  ({ loading, payload: homepageSplash }) =>
    loading || (!loading && !homepageSplash)
);

export const footerSelector = createSelector(
  footerDomainSelector,
  ({ payload }) => payload
);

export const footerLoadingSelector = createSelector(
  footerDomainSelector,
  loadingSelector
);

export const socialMediaSelector = createSelector(
  socialMediaDomainSelector,
  payloadItemsSelector
);

export const socialMediaOrderedSelector = createSelector(
  socialMediaSelector,
  sortByDisplayOrder
);

export const socialMediaLoadingSelector = createSelector(
  socialMediaDomainSelector,
  socialMediaSelector,
  ({ loading }, socialMedia) => loading || (!loading && !socialMedia.length)
);

export const cookiesSelector = createSelector(
  cookiesDomainSelector,
  (cookies) => cookies
);

export const liveChatSelector = createSelector(
  liveChatDomainSelector,
  (liveChat) => liveChat
);

export const broadcastsSelector = createSelector(
  broadcastDomainSelector,
  payloadItemsSelector
);

export const broadcastsLoadingSelector = createSelector(
  broadcastDomainSelector,
  broadcastsSelector,
  ({ loading }, broadcasts) => loading || (!loading && !broadcasts.length)
);

// args is a spread of state and props
export const makeSelectors = (...args) => ({
  topics: topicsSelector(...args),
  topicsSorted: topicsSortedSelector(...args),
  topicsLoading: topicsLoadingSelector(...args),

  articles: articlesSelector(...args),
  articleIds: articleIdsSelector(...args),
  articlesSorted: articlesSortedSelector(...args),
  articlesLoading: articlesLoadingSelector(...args),
  findArticle: (slug) => findArticleBySlug(slug, articlesSelector(...args)),

  topicCollections: topicCollectionsSelector(...args),
  topicCollectionsLoading: topicCollectionsLoadingSelector(...args),
  topicsSlugCategoryMapped: topicsSlugCategoryMappedSelector(...args),
  findTopicCollection: (slug, articleId) => {
    if(slug) return findTopicCollectionBySlug(slug, topicCollectionsSelector(...args));
    if(articleId) return findTopicCollectionByArticleId(articleId, topicCollectionsSelector(...args));
    return null;
  },
  findTopicSplash: (slug) =>
    findTopicCollectionBySlug(slug, topicCollectionsSelector(...args)),
  findTopicSlugByCategory: (title) =>
    findTopicSlugByCategory(title, topicsSlugCategoryMappedSelector(...args)),

  siteConfig: siteConfigSelector(...args),

  homepageSplash: homepageSplashSelector(...args),
  homepageSplashLoading: homepageSplashLoadingSelector(...args),

  topicsSplash: topicsSplashSelector(...args),
  topicsSplashLoading: topicsSplashLoadingSelector(...args),

  footer: footerSelector(...args),
  footerLoading: footerLoadingSelector(...args),

  socialMedia: socialMediaSelector(...args),
  socialMediaOrdered: socialMediaOrderedSelector(...args),
  socialMediaLoading: socialMediaLoadingSelector(...args),

  broadcasts: broadcastsSelector(...args),
  broadcastLoadings: broadcastsLoadingSelector(...args),

  cookies: cookiesSelector(...args),
  liveChat: liveChatSelector(...args),
});
