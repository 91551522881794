import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { getImageDataFromMediaObject } from '../../utils/assets';

import { Link } from '../Link';
import { CardPrimary, CardSecondary, CardTertiary, CardPrimaryInner,
  CardPrimaryContent } from '../Card';
import { Small } from '../Text';
import { Icon } from '../Icon';

export const LinkTopic = styled(Link)`
  border-bottom: 1px solid transparent;
  display: inline-flex;

  &:hover {
    color: inherit;
    border-bottom-color: currentColor;
  }

  ${Small} {
    display: flex;
    align-items: center;
  }

  ${Icon} {
    width: 2.4rem;
    height: 2.4rem;
    margin: 0 0.4em;
  }
`;

export const CardCollectionTitle = styled.h2`
  font-size: 2.4rem;
  font-family: ${({ theme }) => theme.fonts.primary};
  margin-bottom: 1em;

  &:empty {
    display: none;
  }
`;

export const CardCollectionColumn = styled.div``;

export const CardCollectionInner = styled.div``;

export const CardCollectionSection = styled.section`
  background: aliceblue;
  padding: 2.4rem;
`;

export const makeTopicStyles = ({ backgroundImage } = {}) => {
  if (!backgroundImage) return null;
  const imgData = getImageDataFromMediaObject(backgroundImage);
  return css`
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    background-image: url(${imgData.src});
    background-position: center center;
  `;
}

export const CardCollection = styled(({ className, children, topic, ...props }) => {
  const { entryTitle: title, sys } = topic;
  const { slug: topicSlug } = sys;

  const [toggleMore, setToggleMore] = useState(false);
  const toggle = () => setToggleMore(!toggleMore);

  return (
    <CardCollectionSection className={className} {...props}>
      <div className="title-row">
        <CardCollectionTitle>
          <LinkTopic href={`/${topicSlug}`}>
            {title} <Small><Icon icon='chevronRight' /> view all</Small>
          </LinkTopic>
        </CardCollectionTitle>
        {topic.moreInformation && (
          <div>
            <button className="toggle" onClick={toggle}>
              {toggleMore ? 'Close' : topic.moreInformationLabel ? topic.moreInformationLabel : 'Looking for something specific?'}
            </button>
          </div>
        )}
      </div>

      {topic.moreInformation && toggleMore && (
        <div className="more-info"><span
            dangerouslySetInnerHTML={{
              __html: topic.moreInformation,
            }}
          /></div>
      )}
      
      <CardCollectionInner>
        {children}
      </CardCollectionInner>
    </CardCollectionSection>
  )
})`
  .title-row {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      button.toggle {
        border: none;
        background: transparent;
        cursor: pointer;
        padding-bottom: 0.4rem;
        &:hover {
          border-bottom: 1px solid;
        }
      }
      @media screen and (max-width: 700px) {
        flex-direction: column;
        button.toggle {
          margin-bottom: 2.4rem;
        }
      }
  }

  .more-info {
    width: 100%;
    background: rgba(255,255,255,.6);
    padding: 2.4rem;
    margin-bottom: 2.4rem;
  }
  ${p => makeTopicStyles(p.topic)}

  ${CardTertiary} ${CardPrimaryContent} {
    padding: 2.4rem 1.6rem 1.6rem;
  }

  ${CardCollectionInner} {
    display: grid;
    grid-gap: 2rem;
    align-items: flex-start;

    ${Link} {
      border-bottom: none;
      display: flex;

      ${CardPrimary} {
        width: 100%;
        border: 0;
      }

      ${CardSecondary} {
        max-width: none;
      }

      ${CardPrimaryInner} {
        width: 100%;
      }

      ${CardTertiary} {
        align-self: flex-start;
      }
    }
  }

  ${CardCollectionColumn} {
    display: grid;
    grid-gap: 2rem;
  }
`;

CardCollection.propTypes = {
  topic: PropTypes.shape({
    entryTitle: PropTypes.string,
    backgroundImage: PropTypes.shape({
      asset: PropTypes.shape({
        sys: PropTypes.shape({
          baseUris: PropTypes.arrayOf(PropTypes.string),
          uri: PropTypes.string,
        }),
      }),
    }),
    sys: PropTypes.shape({
      slug: PropTypes.string,
    }),
  }),
};

CardCollection.defaultProps = {
  topic: {
    sys: {},
  },
};
