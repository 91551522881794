import React from 'react';
import styled from 'styled-components';
import { useParams } from '@reach/router';
import { path } from 'ramda';

import { useApi } from '../../api';
import { getImageDataFromMediaObject } from '../../utils/assets';
import { makeRoute } from '../../routes';

import { Helmet } from '../../components/Helmet';
import { Page, PageTopic } from '../../components/Page';
import { useScrollToTop } from '../../components/ScrollToTop';
import { WelcomeSplash, SplashInner, SplashMessageTitle, SplashMessage } from '../../components/WelcomeSplash';
import { CardSecondary, CardLink, CardPrimaryFigure,
  CardPrimaryInner, CardPrimaryContent } from '../../components/Card';
import { SkeletonSplash, SkeletonArticlesList } from '../../components/Skeleton';

export const ArticleList = styled.ol`
  padding: 0;
  margin: 0;
  list-style-type: none;
`;
export const ArticleItem = styled.li``;

export const renderPageMeta = (topicCollection) => {
  if (!topicCollection) return null;
  const asset = getImageDataFromMediaObject(path(['splash', 'categoryImage'], topicCollection));
  const topicTitle = path(['topic', 'entryTitle'], topicCollection);
  return (
    <Helmet title={topicTitle}>
      {asset.src && <meta property="og:image" content={asset.src} />}
      {asset.properties && <meta property="og:image:width" content={asset.properties.width} />}
      {asset.properties && <meta property="og:image:height" content={asset.properties.height} />}
    </Helmet>
  );
}

export const Articles = ({ className, topicId }) => {
  useScrollToTop();

  const params = useParams();
  const { findTopicCollection, topicCollectionsLoading } = useApi();

  const topicCollection = findTopicCollection(topicId || params.topicId);

  return (
    <Page className={className}>
      {renderPageMeta(topicCollection)}
      <PageTopic>
        <React.Fragment>
          {topicCollectionsLoading ? <SkeletonSplash /> : (
            topicCollection.splash && <WelcomeSplash
              title={path(['splash', 'entryTitle'], topicCollection)}
              media={path(['splash', 'categoryImage'], topicCollection)}
            />
          )}
          <ArticleList>
            {topicCollectionsLoading ? <SkeletonArticlesList /> : (
              topicCollection.articles.map(article => {
                const href = makeRoute(topicCollection.topic.sys.slug, article.sys.slug);
                const { thumbnail = {}, articleTitle, introductoryText } = article;
                const img = getImageDataFromMediaObject(thumbnail);
                return (
                  <ArticleItem key={article.sys.id}>
                    <CardLink href={href}>
                      <CardSecondary
                        title={articleTitle}
                        description={introductoryText}
                        imgSrc={img.src}
                        imgAlt={img.alt}
                      />
                    </CardLink>
                  </ArticleItem>
                );
              })
            )}
          </ArticleList>
        </React.Fragment>
      </PageTopic>
    </Page>
  );
}

export default styled(Articles)`
  ${WelcomeSplash},
  ${SkeletonSplash} {
    margin-bottom: 2rem;
    min-height: 50vh;
  }

  ${ArticleItem},
  ${SkeletonArticlesList} {
    margin-bottom: 2rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  ${CardSecondary} {
    max-width: none;
    width: 100%;
  }

  ${CardPrimaryInner} {
    width: 100%;
    justify-content: space-between;
  }

  ${CardPrimaryContent} {

  }
  ${CardPrimaryFigure} {
    flex-basis: 35%;
  }

  ${({ theme }) => theme.media.bravo`
    ${SplashMessage} {
      max-width: none;
    }
    ${SplashMessageTitle} {
      width: 100%;
    }
  `}

  ${({ theme }) => theme.media.charlie`
    ${CardPrimaryInner} {
      flex-direction: column;
    }
    ${CardPrimaryContent} {
      flex: 0 1 auto;
    }
    ${CardPrimaryFigure} {
      width: 100%;
      height: 16rem;
      position: relative;
      overflow: hidden;
      padding-bottom: 56.75%;
    }
    ${SplashInner} {
      padding-top: 15rem;
    }
  `}

`;
