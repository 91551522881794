/* eslint-disable no-undef */
import React from 'react';
import { css } from 'styled-components';

export const liveChatStyles = css`
  .embeddedServiceHelpButton .helpButton .uiButton {
    background-color: #005290;
    font-family: "Arial", sans-serif;
  }
  .embeddedServiceHelpButton .helpButton .uiButton:focus {
    outline: 1px solid #005290;
  }
`;

const initChat = () => {
  const initESW = function (gslbBaseURL) {
    embedded_svc.settings.displayHelpButton = true //Or false
    embedded_svc.settings.language = "" //For example, enter 'en' or 'en-US'
    embedded_svc.settings.defaultMinimizedText = 'Chat with a Student'; //(Defaults to Chat with an Expert)
    embedded_svc.settings.disabledMinimizedText = 'Chat is Offline'; //(Defaults to Agent Offline)
    //embedded_svc.settings.loadingText = ''; //(Defaults to Loading)
    //embedded_svc.settings.storageDomain = 'yourdomain.com'; //(Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)
    // Settings for Chat
    //embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
    // Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
    // Returns a valid button ID.
    //};
    //embedded_svc.settings.prepopulatedPrechatFields = {}; //Sets the auto-population of pre-chat form fields
    //embedded_svc.settings.fallbackRouting = []; //An array of button IDs, user IDs, or userId_buttonId
    //embedded_svc.settings.offlineSupportMinimizedText = '...'; //(Defaults to Contact Us)
    embedded_svc.settings.enabledFeatures = ["LiveAgent"]
    embedded_svc.settings.entryFeature = "LiveAgent"
    embedded_svc.init(
      "https://lse360.my.salesforce.com",
      "https://lseportal.force.com/support",
      gslbBaseURL,
      "00D58000000JYzR",
      "SSC_Welcome_Live_Chat",
      {
        baseLiveAgentContentURL: "https://c.la1-c2-lo2.salesforceliveagent.com/content",
        deploymentId: "5724I0000004H3R",
        buttonId: "5734I0000004HrK",
        baseLiveAgentURL: "https://d.la1-c2-lo2.salesforceliveagent.com/chat",
        eswLiveAgentDevName: "EmbeddedServiceLiveAgent_Parent04I4I000000CaWHUA0_173d856a2f4",
        isOfflineSupportEnabled: false,
      }
    )
  };

  if (!window.embedded_svc) {
    var s = document.createElement("script")
    s.setAttribute(
      "src",
      "https://lse360.my.salesforce.com/embeddedservice/5.0/esw.min.js"
    )
    s.onload = function () {
      initESW(null)
    }
    document.body.appendChild(s)
  } else {
    initESW("https://service.force.com")
  }
}


export const LiveChat = () => {
  React.useEffect(() => {
    (async () => {
      await fetch('https://service.force.com/embeddedservice/5.0/esw.min.js', {
        mode: 'no-cors',
      });
      initChat();
    })();
  }, []);
  return null;
};
