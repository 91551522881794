/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';

export const Li = styled(({ css, ...props }) => <li {...props} />)`
  margin-bottom: 1.6rem;
  ${p => p.css}
`;

export const Ol = styled(({ css, ...props }) => <ol {...props} />)`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: inherit;
  font-size: 1.6rem;
  padding-left: 3rem;
  margin: 0;
  ${p => p.css}
`;

export const Ul = styled(({ css, ...props }) => <ul {...props} />)`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: inherit;
  font-size: 1.6rem;
  padding-left: 1.4rem;
  margin: 0;
  list-style-type: none;
  ${p => p.css}

  ${Li} {
    &:before {
      content: '•';
      color: currentColor;
      margin-right: 0.8rem;
      transform: scale(1.6);
      display: inline-block;
    }
  }
`;
