import React from 'react';
import PropTypes from 'prop-types';
import { Helmet as ReactHelmet } from 'react-helmet';

import { meta as siteMeta } from '../../config';

import { useApi } from '../../api';

export const SEPARATOR = '|'

export const Helmet = ({ title, description, meta, ...props }) => {

  const metaTitle = Array.isArray(title) ? title.join(` ${SEPARATOR} `) : title;

  const { siteConfig } = useApi();

  return (
    <ReactHelmet
      titleTemplate={`%s ${SEPARATOR} ${siteConfig.siteTitle}`}
      defaultTitle={`${siteConfig.siteTitle}`}
      htmlAttributes={{ lang: siteMeta.lang }}
      title={metaTitle}
      meta={[
        {
          name: 'description',
          content: description,
        },
      ].concat(meta)}
      {...props}
    />
  );
};

Helmet.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  description: PropTypes.string,
  meta: PropTypes.shape({}),
};

Helmet.defaultProps = {
  title: '',
  description: siteMeta.description,
  meta: {},
};
