const UUID_ARTICLES = ':topicId';
const UUID_ARTICLE = ':articleId';

export const routes = {
  HOME: '/',
  SEARCH: `/search`,
  ARTICLES: `/${UUID_ARTICLES}`,
  ARTICLE: `/${UUID_ARTICLES}/${UUID_ARTICLE}`,
  UUID_ARTICLES,
  UUID_ARTICLE,
  PREVIEW: `/preview/:guid`,
};

export const makeRoute = (topicId, articleId) => {
  const paths = [];
  if (topicId) paths.push(topicId);
  if (articleId) paths.push(articleId);
  return `${routes.HOME}${paths.join('/')}`;
};

export default routes;
