import React from 'react';
import ReactHtmlParser, { processNodes } from 'react-html-parser';
import { css } from 'styled-components';

import { P, Strong, Em } from '../../components/Text';
import { Ol, Ul, Li } from '../../components/List';
import { Link } from '../../components/Link';

const sanitizeNodeAttribs = nodeAttribs => {
  const { style, ...attribs } = nodeAttribs;
  return {
    ...attribs,
    css: css`${style}`,
  }
};

const nodeTransformer = (node, index) => {
  if (node.type === 'tag') {
    const children = processNodes(node.children, nodeTransformer);
    switch (node.name) {
      case 'ul':
        return React.createElement(Ul, {
          key: index,
          ...sanitizeNodeAttribs(node.attribs),
        }, children);

      case 'ol':
        return React.createElement(Ol, {
          key: index,
          ...sanitizeNodeAttribs(node.attribs),
        }, children);

      case 'li':
        return React.createElement(Li, {
          key: index,
          ...sanitizeNodeAttribs(node.attribs),
        }, children);

      case 'b':
      case 'strong':
        return React.createElement(Strong, {
          key: index,
          ...sanitizeNodeAttribs(node.attribs),
        }, children);

      case 'em':
      case 'i':
        return React.createElement(Em, {
          key: index,
          ...sanitizeNodeAttribs(node.attribs),
        }, children);

      case 'p':
        return React.createElement(P, {
          key: index,
          ...sanitizeNodeAttribs(node.attribs),
        }, children);

      case 'a':
        return React.createElement(Link, {
          key: index,
          ...sanitizeNodeAttribs(node.attribs),
        }, children);
    }
  }
};

export const Markdown = ({ source }) => ReactHtmlParser(source, {
  transform: nodeTransformer,
});
