import React from 'react';
import styled from 'styled-components';
import { path } from 'ramda';

import { useApi } from '../../api';
import { getImageDataFromMediaObject } from '../../utils/assets';
import { useSearch, useSearchQueryParam } from '../../utils/search';
import { makeRoute } from '../../routes';

import { Page, PageTopic } from '../../components/Page';
import { useScrollToTop } from '../../components/ScrollToTop';
import { CardSecondary, CardLink, CardPrimaryFigure,
  CardPrimaryInner, CardPrimaryContent } from '../../components/Card';
import { SkeletonArticlesList } from '../../components/Skeleton';
import { Title } from '../../components/Title';
import { Em, P } from '../../components/Text';

export const SearchResultsTitle = styled(Title).attrs({
  tag: 'h1',
})`
  font-size: 4.8rem;
`;

export const ArticleList = styled.ol`
  padding: 0;
  margin: 0;
  list-style-type: none;
`;
export const ArticleItem = styled.li``;

export const makeUniqueItems = (items = []) => {
  // reduce items to key-value map
  const mappedItems = items.reduce((acc, item) => {
    if (!acc.has(item.sys.id)) acc.set(item.sys.id, item);
    return acc;
  }, new Map());
  return Array.from(mappedItems.values());
};

export const renderSearchResults = (searchResults) => {
  const { findTopicSlugByCategory } = useApi();

  if (!searchResults || (searchResults && !searchResults.items.length)) return (
    <P>Sorry, looks like we couldn&apos;t find anything matching your query.</P>
  );

  return searchResults.items.map(article => {
    const topicTitle = path(['welcomeCategory', 'welcomeCategoryList', 0], article);
    const href = makeRoute(findTopicSlugByCategory(topicTitle), article.sys.slug);
    const { thumbnail = {}, articleTitle, introductoryText } = article;
    const img = getImageDataFromMediaObject(thumbnail);
    return (
      <ArticleItem key={article.sys.id}>
        <CardLink href={href}>
          <CardSecondary
            title={articleTitle}
            description={introductoryText}
            imgSrc={img.src}
            imgAlt={img.alt}
          />
        </CardLink>
      </ArticleItem>
    );
  });
}

const Search = ({ className }) => {
  useScrollToTop();

  const query = useSearchQueryParam();
  const { results, busy } = useSearch(query, 50);

  return (
    <Page className={className}>
      {query === ''
        ? (
          <PageTopic>
            <SearchResultsTitle>Oops!</SearchResultsTitle>
            <ArticleList>
              <P>Sorry, but we can&apos;t search for nothing.</P>
            </ArticleList>
          </PageTopic>
        ) : (
          <PageTopic>
            <SearchResultsTitle>Results for: <Em>{query}</Em></SearchResultsTitle>
            <ArticleList>
              {busy ? <SkeletonArticlesList /> : renderSearchResults(results)}
            </ArticleList>
          </PageTopic>
        )}
    </Page>
  );
}

export default styled(Search)`
  ${ArticleItem},
  ${SkeletonArticlesList} {
    margin-bottom: 2rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  ${SearchResultsTitle} {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  ${CardSecondary} {
    max-width: none;
    width: 100%;
  }

  ${CardPrimaryInner} {
    width: 100%;
    justify-content: space-between;
  }

  ${CardPrimaryContent} {

  }
  ${CardPrimaryFigure} {
    flex-basis: 35%;
  }

  ${({ theme }) => theme.media.bravo`

  `}

  ${({ theme }) => theme.media.charlie`
    ${CardPrimaryInner} {
      flex-direction: column;
    }
    ${CardPrimaryContent} {
      flex: 0 1 auto;
    }
    ${CardPrimaryFigure} {
      width: 100%;
      height: 16rem;
      position: relative;
      overflow: hidden;
      padding-bottom: 56.75%;
    }
  `}
`;
