import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Img, ImgInner, ImgComponent } from '../../Img';
import { Figure } from '../../Figure';
import { DateTime } from '../../DateTime';
//testing comment for push to github and merge wirh staging
export const CardPrimaryContainer = styled.div`
  max-width: 59rem;
  background: ${({ theme }) => theme.colors.coruscant};
  color: ${({ theme }) => theme.colors.naboo};
  font-family: ${({ theme }) => theme.fonts.primary};
  display: flex;
`;
export const CardPrimaryInner = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
export const CardPrimaryFigure = styled(Figure)`
  width: 100%;
  height: 16rem;
  position: relative;
  overflow: hidden;
  padding-bottom: 56.75%;

  ${Img} {
    height: 100%;
    width: 100%;
    position: absolute;

    ${ImgInner} {
      height: 100%;
      width: 100%;
    }

    ${ImgComponent} {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
export const CardPrimaryContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.6rem 2.4rem;
`;

export const CardPrimaryTitle = styled.h3`
  font-size: 2rem;
  line-height: 1.2;
`;

export const CardPrimarySubtitle = styled.h4`
  line-height: 1.4;
  margin-top: 1.6rem;
  &:empty {
    display: none;
  }
`;

export const CardPrimaryDescription = styled.p`
  font-weight: 300;
  &:empty {
    display: none;
  }
`;

export const CardPrimaryContentBody = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
`;

export const CardPrimaryContentFooter = styled.footer`
  color: ${({ theme }) => theme.colors.naboo};

  ${DateTime} {
    color: inherit;
  }
`;

export const CardPrimary = styled(({
  date,
  title,
  imgSrc,
  imgAlt,
  subtitle,
  className,
  description,
  ...props
}) => (
  <CardPrimaryContainer className={className} {...props}>
    <CardPrimaryInner>
      {imgSrc && (
        <CardPrimaryFigure>
          <Img async src={imgSrc} alt={imgAlt} />
        </CardPrimaryFigure>
      )}
      <CardPrimaryContent>
        <CardPrimaryContentBody>
          <CardPrimaryTitle>{title}</CardPrimaryTitle>
          {subtitle && <CardPrimarySubtitle>{subtitle}</CardPrimarySubtitle>}
          {description && <CardPrimaryDescription>{description}</CardPrimaryDescription>}
        </CardPrimaryContentBody>
        {date && (
          <CardPrimaryContentFooter>
            <DateTime dateTime={date} />
          </CardPrimaryContentFooter>
        )}
      </CardPrimaryContent>
    </CardPrimaryInner>
  </CardPrimaryContainer>
))`

`;

CardPrimary.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.string,
  readTime: PropTypes.string,
  imgSrc: PropTypes.string,
  imgAlt: PropTypes.string,
};

CardPrimary.defaultProps = {};

CardPrimary.displayName = 'CardPrimary';
