import styled from 'styled-components';

import { Link } from '../Link';

export * from './CardPrimary';
export * from './CardSecondary';
export * from './CardTertiary';

export const CardLink = styled(Link)`
  display: block;
  border-bottom: none;
  padding-bottom: 0;
`;
